import useSWR from 'swr';
import axios from 'axios';
import {fromPairs} from 'lodash';
import {useIntl} from 'react-intl';
import useSWRImmutable from 'swr/immutable';
import React, {useMemo, useCallback, useEffect, useState} from 'react';

import {SelectProps} from '@material-ui/core';

import {BackButton} from './BackButton';
import {ViewToggle} from './ViewToggle';
import {LabelSearch} from './LabelSearch';
import {PageNumbers} from './PageNumbers';
import {PageOptions} from './PageOptions';
import {LabelSelected} from './LabelSelected';
import {SortDirToggle} from './SortDirToggle';
import {SortId, useDataViewer} from './reducer';
import {AllLabelsButton} from './AllLabelsButton';
import {
  getLabelData,
  getListData,
  getFileCount,
  getLabelCounts,
  scaleAnnotation,
  getNumberOfClasses,
} from './utils';

import URL from './../../../config/url';
import {GridViewer} from './../GridViewer';
import {LabelViewer} from './../LabelViewer';
import {GridSkeleton} from './../../Skeleton';
import {GridViewerItem} from './../GridViewerItem';
import {Project} from './../../../types/project/Project';
import {DataViewerTooltip} from './../DataViewerTooltip';
import {DataViewerSummary} from './../DataViewerSummary';
import {Response} from './../../../types/response/Response';
import {DataSplitType} from './../../../types/dataset/Dataset';
import {StudioSelect} from './../../../base-components/StudioSelect';
import {DataViewerItemThumbnail} from './../DataViewerItemThumbnail';
import {StudioTab, StudioTabs} from './../../../base-components/StudioTab';
import {DataViewerListItemThumbnail} from './../DataViewerListItemThumbnail';
import {DatasetListRequest} from './../../../types/dataset/DatasetListRequest';
import {DatasetLabelsRequest} from './../../../types/dataset/DatasetLabelsRequest';
import {DatasetSummaryResponse} from './../../../types/dataset/DatasetSummaryResponse';
import {DatasetDistributionResponse} from './../../../types/dataset/DatasetDistributionResponse';
import {DatasetGetAnnotationsResponse} from './../../../types/dataset/DatasetGetAnnotationsResponse';
import {
  ItemType,
  ListViewer,
  ListViewerItem,
  ListViewerLabel,
  useListViewerRow,
  ListViewerFilename,
  ListViewerFilesize,
  ListViewerDimensions,
} from './../ListViewer';

import './DataViewer.scss';

const LABEL_PAGE_SIZE = 5;
const LABEL_FILE_COUNT = 20;

const LIST_PAGE_SIZE = 50;
const LIST_THUMB_SIZE = 55;

const GRID_THUMB_SIZE = 93;
const MAX_FILES_BY_REQUEST = 25;

export type DataViewerProps = {
  projectId: string;
};

export const getImageUrl = (
  projectId: string,
  filenames: string[],
  splitType?: DataSplitType,
  resourceId?: string,
  width?: number,
  height?: number
) => {
  const joinedFilenames = filenames
    .map(fileName => fileName.replace(',', '%2C'))
    .join(',');

  return URL.DATASET_GET_BY_FILENAMES(projectId, {
    filenames: joinedFilenames,
    splitType,
    resolution: width ? `${width}:${height}` : undefined,
    resourceId,
  });
};

export type ImageDimensionMap = {
  [id: string]: {
    height: number;
    width: number;
    size: number;
  };
};

type SplitTab = {
  value: DataSplitType;
  label: string;
  count?: number;
  countLabel: string;
  percentageLabel: string;
};

type SortOptionsProps = {
  sort: SortId;
  columns: Array<{
    id: 'image' | 'filename' | 'size' | 'width' | 'lastEdited' | 'label';
    label: string;
    sortable: boolean;
  }>;

  onSort(sort: SortId): void;
};

function SortOptions(props: SortOptionsProps) {
  return (
    <StudioSelect
      options={fromPairs(
        props.columns
          .filter(column => column.label.length > 0)
          .map(column => [column.id, column.label])
      )}
      SelectProps={{
        value: props.sort,
        onChange: (event: Parameters<NonNullable<SelectProps['onChange']>>[0]) => {
          props.onSort(event.target.value as SortId);
        },
      }}
    />
  );
}

export const DataViewer = ({projectId}: DataViewerProps) => {
  const {
    page,
    sort,
    view,
    label,
    setPage,
    setSort,
    sortDir,
    setLabel,
    splitType,
    labelField,
    toggleView,
    setSplitType,
    toggleSortDir,
    setLabelField,
    toggleSortHeading,
  } = useDataViewer();
  const intl = useIntl();

  const getImageThumbnailUrl = useCallback(
    (
      filenames: string[],
      splitType?: DataSplitType,
      resourceId?: string,
      size?: number
    ) =>
      getImageUrl(
        projectId,
        filenames,
        splitType,
        resourceId,
        size || GRID_THUMB_SIZE,
        size || GRID_THUMB_SIZE
      ),
    [projectId]
  );

  const fetcher = useCallback(async (url: string) => {
    const res = await axios.get(url);

    return res.data;
  }, []);

  const {data: projectResponse} = useSWR<Response<Project>>(
    URL.PROJECT_GET(projectId),
    fetcher
  );

  const resourceId = useMemo(() => projectResponse?.body.dataSet?.id, [projectResponse]);
  const dataLoader = useMemo(() => projectResponse?.body.dataSet?.dataLoader, [
    projectResponse,
  ]);
  const noMetadata = useMemo(() => dataLoader === 'Raw', [dataLoader]);
  const [areRawImagesOnly, setAreRawImagesOnly] = useState(
    noMetadata || dataLoader === 'Custom'
  );

  useEffect(() => {
    const recalculatedAreRawImagesOnly = noMetadata || dataLoader === 'Custom';

    if (recalculatedAreRawImagesOnly !== areRawImagesOnly) {
      setAreRawImagesOnly(recalculatedAreRawImagesOnly);
      setSort(recalculatedAreRawImagesOnly ? 'filename' : 'label');
      setSplitType(recalculatedAreRawImagesOnly ? undefined : 'TRAIN');
    }
  }, [noMetadata, dataLoader, areRawImagesOnly, setSort, setSplitType]);

  const columns: SortOptionsProps['columns'] = useMemo(
    () => [
      {id: 'image', label: '', sortable: false},
      {
        id: 'filename',
        label: intl.formatMessage({id: 'dataViewer.fileName'}),
        sortable: true,
      },
      {
        id: 'size',
        label: intl.formatMessage({id: 'dataViewer.fileSize'}),
        sortable: false,
      },
      {
        id: 'width',
        label: intl.formatMessage({id: 'dataViewer.dimensions'}),
        sortable: false,
      },
    ],
    [intl]
  );

  useEffect(() => {
    if (!areRawImagesOnly) {
      columns.push({
        id: 'label',
        label: intl.formatMessage({id: 'dataViewer.label'}),
        sortable: true,
      });
    }
  }, [intl, columns, areRawImagesOnly]);

  const {data: summaryData} = useSWRImmutable<DatasetSummaryResponse>(
    !areRawImagesOnly && resourceId ? resourceId + URL.DATASET_SUMMARY(projectId) : null,
    () => fetcher(URL.DATASET_SUMMARY(projectId))
  );

  const {data: distributionData} = useSWRImmutable<DatasetDistributionResponse>(
    !noMetadata && resourceId ? resourceId + URL.DATASET_DISTRIBUTION({projectId}) : null,
    () => fetcher(URL.DATASET_DISTRIBUTION({projectId}))
  );

  const totalFileCount = useMemo(() => getFileCount(distributionData), [
    distributionData,
  ]);
  const labelNameMapping = useMemo(() => distributionData?.body?.labelNameMapping || {}, [
    distributionData,
  ]);
  const labelNameMapper = useCallback(
    (labelName: string) => labelNameMapping[labelName] || labelName,
    [labelNameMapping]
  );

  const formatTab = useCallback(
    ({
      selectedValue,
      tabLabel,
      distribution,
      proportion,
    }: {
      selectedValue: DataSplitType;
      tabLabel: string;
      distribution?: DatasetDistributionResponse['body']['trainDistribution'];
      proportion?: number;
    }) => ({
      value: selectedValue,
      label: intl.formatMessage({id: tabLabel}),
      count: distribution?.imagesCount,
      countLabel: intl.formatMessage(
        {id: 'dataViewer.tabCount'},
        {
          distributionCount: distribution?.imagesCount || 0,
          totalCount: totalFileCount,
        }
      ),
      percentageLabel: intl.formatMessage(
        {id: 'dataViewer.tabPercentage'},
        {
          percentage: intl.formatNumber((proportion || 0.0) / 100.0, {
            style: 'percent',
            maximumFractionDigits: 0,
          }),
        }
      ),
    }),
    [intl, totalFileCount]
  );

  const SPLIT_TABS: SplitTab[] = useMemo(
    () => [
      formatTab({
        selectedValue: 'TRAIN',
        tabLabel: 'dataViewer.trainingTab',
        distribution: distributionData?.body.trainDistribution,
        proportion: distributionData?.body.trainProportion,
      }),
      formatTab({
        selectedValue: 'VALIDATION',
        tabLabel: 'dataViewer.validationTab',
        distribution: distributionData?.body.validationDistribution,
        proportion: distributionData?.body.validationProportion,
      }),
      formatTab({
        selectedValue: 'TEST',
        tabLabel: 'dataViewer.testingTab',
        distribution: distributionData?.body.testDistribution,
        proportion: distributionData?.body.testProportion,
      }),
    ],
    [distributionData, formatTab]
  );

  const {data: annotationData} = useSWRImmutable<DatasetGetAnnotationsResponse>(
    areRawImagesOnly || SPLIT_TABS.find(tab => tab.value === splitType)?.count
      ? URL.DATASET_GET_ANNOTATIONS(projectId, {splitType, resourceId})
      : null,
    fetcher
  );

  const isLabelView = useMemo(
    () => sort === 'label' && view === 'grid' && label.length === 0,
    [sort, view, label]
  );
  const pageSize = useMemo(() => (isLabelView ? LABEL_PAGE_SIZE : LIST_PAGE_SIZE), [
    isLabelView,
  ]);
  const startFrom = useMemo(() => (page - 1) * pageSize, [page, pageSize]);

  const listQuery: DatasetListRequest = useMemo(
    () => ({
      label,
      pageSize,
      sort,
      sortDir,
      splitType,
      startFrom,
    }),
    [label, pageSize, sort, sortDir, splitType, startFrom]
  );
  const labelsQuery: DatasetLabelsRequest = useMemo(
    () => ({
      fileCount: LABEL_FILE_COUNT,
      pageSize,
      sortDir,
      splitType,
      startFrom,
    }),
    [pageSize, sortDir, splitType, startFrom]
  );

  const labelsData = useMemo(() => getLabelData(annotationData, labelsQuery), [
    annotationData,
    labelsQuery,
  ]);
  const listData = useMemo(
    () => getListData(annotationData, listQuery, sort === 'label'),
    [annotationData, listQuery, sort]
  );
  const itemCount = useMemo(
    () => (isLabelView ? labelsData?.count : listData?.count) || 0,
    [isLabelView, labelsData, listData]
  );
  const pageCount = useMemo(() => Math.max(1, Math.ceil(itemCount / pageSize)), [
    itemCount,
    pageSize,
  ]);

  const currentFileNames = useMemo(
    () =>
      isLabelView
        ? labelsData?.labels.flatMap(data => {
            const files = data.files.slice(0, LABEL_FILE_COUNT);

            return files.map(file => file.filename);
          })
        : listData?.files.map(file => file.filename),
    [isLabelView, labelsData, listData]
  );

  const {data: imageDimensions} = useSWRImmutable<ImageDimensionMap>(
    currentFileNames?.length && view ? currentFileNames.join() + view : null,
    async () => {
      let details: {w: number; h: number; s: number}[] = [];

      for (let i = 0; i < currentFileNames.length; i += MAX_FILES_BY_REQUEST) {
        const batch = currentFileNames.slice(i, i + MAX_FILES_BY_REQUEST);
        const url = getImageThumbnailUrl(
          batch,
          splitType,
          resourceId,
          view === 'list' ? LIST_THUMB_SIZE : undefined
        );

        const {headers} = await axios.get(url, {responseType: 'blob'});
        const xSizesHeader = headers?.['x-sizes'];
        const imageDetails: {w: number; h: number; s: number}[] = JSON.parse(
          xSizesHeader
        );
        details = details.concat(imageDetails);
      }

      return currentFileNames.reduce<ImageDimensionMap>((acc, curr, i) => {
        acc[curr] = {
          height: details[i].h,
          width: details[i].w,
          size: details[i].s,
        };
        return acc;
      }, {});
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60000,
      focusThrottleInterval: 60000,
    }
  );

  const scaleTooltipAnnotation = useCallback(
    (item: ItemType) =>
      scaleAnnotation(
        item.annotation,
        {
          width: item.width ?? 0,
          height: item.height ?? 0,
        },
        {
          width: imageDimensions?.[item.filename]?.width ?? item.width ?? 0,
          height: imageDimensions?.[item.filename]?.height ?? item.height ?? 0,
        }
      ),
    [imageDimensions]
  );

  const scaleThumbAnnotation = useCallback(
    (item: ItemType) =>
      scaleAnnotation(
        item.annotation,
        {
          width: item.width ?? 0,
          height: item.height ?? 0,
        },
        {
          width: GRID_THUMB_SIZE,
          height: GRID_THUMB_SIZE,
        }
      ),
    []
  );

  const renderViewer = () => {
    if (isLabelView) {
      return labelsData?.labels?.length ? (
        <LabelViewer
          labels={labelsData.labels}
          labelNameMapper={labelNameMapper}
          limit={LABEL_FILE_COUNT}
          onLabelClick={setLabel}
        >
          {(label, file, filenames, pos) => {
            return (
              <DataViewerTooltip
                filename={file.filename}
                label={labelNameMapper(label)}
                size={imageDimensions?.[file.filename]?.size || file.size}
                width={imageDimensions?.[file.filename]?.width || file.width}
                height={imageDimensions?.[file.filename]?.height || file.height}
                url={getImageUrl(projectId, [file.filename], splitType, resourceId)}
                annotation={scaleTooltipAnnotation(file)}
              >
                <DataViewerItemThumbnail
                  url={getImageThumbnailUrl(filenames, splitType, resourceId)}
                  pos={pos}
                  annotation={scaleThumbAnnotation(file)}
                />
              </DataViewerTooltip>
            );
          }}
        </LabelViewer>
      ) : (
        <GridSkeleton
          tileHeight={128}
          tileWidth={128}
          tileMarginX={14}
          tileMarginY={14}
          minTiles={30}
        />
      );
    } else if (view === 'grid') {
      return (
        <GridViewer count={itemCount} label={labelNameMapper(label)}>
          {listData.files.map((file: ItemType, index) => (
            <GridViewerItem key={file.id}>
              <DataViewerTooltip
                filename={file.filename}
                label={labelNameMapper(file.label || '')}
                lastEdited={file.lastEdited}
                size={imageDimensions?.[file.filename]?.size || file.size}
                width={imageDimensions?.[file.filename]?.width || file.width}
                height={imageDimensions?.[file.filename]?.height || file.height}
                url={getImageUrl(projectId, [file.filename], splitType, resourceId)}
                annotation={scaleTooltipAnnotation(file)}
              >
                <DataViewerItemThumbnail
                  pos={index}
                  annotation={scaleThumbAnnotation(file)}
                  url={getImageThumbnailUrl(
                    listData?.files.map(file => file.filename),
                    splitType,
                    resourceId
                  )}
                />
              </DataViewerTooltip>
            </GridViewerItem>
          ))}
        </GridViewer>
      );
    } else {
      const ListViewerTooltipThumbnail = ({pos}: {pos: number}) => {
        const {item} = useListViewerRow();
        const filenames = listData?.files.map(file => file.filename);

        return (
          <DataViewerTooltip
            filename={item.filename}
            label={labelNameMapper(item.label!)}
            lastEdited={item.lastEdited}
            size={imageDimensions?.[item.filename]?.size || item.size}
            width={imageDimensions?.[item.filename]?.width || item.width}
            height={imageDimensions?.[item.filename]?.height || item.height}
            url={getImageUrl(projectId, [item.filename], splitType, resourceId)}
            annotation={scaleTooltipAnnotation(item)}
          >
            <DataViewerListItemThumbnail
              url={getImageThumbnailUrl(
                filenames,
                splitType,
                resourceId,
                LIST_THUMB_SIZE
              )}
              pos={pos}
              width={LIST_THUMB_SIZE}
              height={LIST_THUMB_SIZE}
            />
          </DataViewerTooltip>
        );
      };

      return listData?.files ? (
        <ListViewer
          columns={columns}
          items={listData.files.map(file => ({
            ...file,
            height: imageDimensions?.[file.filename]?.height || file.height,
            width: imageDimensions?.[file.filename]?.width || file.width,
            size: imageDimensions?.[file.filename]?.size || file.size,
          }))}
          onSort={id => id !== 'image' && toggleSortHeading(id)}
          projectId={projectId}
          sort={sort}
          sortDir={sortDir}
        >
          {(item, pos) => (
            <>
              <ListViewerItem>
                <ListViewerTooltipThumbnail pos={pos} />
              </ListViewerItem>
              <ListViewerFilename />
              <ListViewerFilesize />
              <ListViewerDimensions />
              <ListViewerLabel label={labelNameMapper(item.label!)} />
            </>
          )}
        </ListViewer>
      ) : (
        <GridSkeleton
          tileHeight={128}
          tileWidth={128}
          tileMarginX={14}
          tileMarginY={14}
          minTiles={itemCount}
        />
      );
    }
  };

  return (
    <div className="data-viewer2">
      <BackButton />
      <div className="data-viewer2__content">
        <DataViewerSummary
          pending={false}
          name={
            areRawImagesOnly
              ? projectResponse?.body.dataSet?.name
              : distributionData?.body.datasetName
          }
          date={summaryData?.creationDate}
          numberOfClasses={getNumberOfClasses(distributionData)}
          fileCount={areRawImagesOnly ? annotationData?.images.length : totalFileCount}
          sizeOfDataset={summaryData?.size}
          filesByLabel={getLabelCounts(distributionData).map(({label, count}) => ({
            label: labelNameMapper(label),
            count,
          }))}
          onBarClick={setLabel}
          purposeOfDataset={projectResponse?.body.dataSet?.purpose}
        />
        <div className="data-viewer2__header">
          {!areRawImagesOnly && (
            <div className="data-viewer2__tabs">
              <StudioTabs
                value={splitType}
                onChange={(_event, index) => setSplitType(index)}
                className="dashboard__tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                {SPLIT_TABS.map(splitTab => (
                  <StudioTab
                    className="data-viewer2__tab"
                    key={splitTab.label}
                    label={
                      <span className="data-viewer2__tab-content">
                        <span className="data-viewer2__tab-title">{splitTab.label}</span>
                        <span className="data-viewer2__tab-stats">
                          <span>{splitTab.countLabel}</span>
                          <span>{splitTab.percentageLabel}</span>
                        </span>
                      </span>
                    }
                    value={splitTab.value}
                  />
                ))}
              </StudioTabs>
            </div>
          )}
          <div className="data-viewer2__controls">
            <>
              {!areRawImagesOnly && (
                <LabelSearch
                  labelField={labelField}
                  onChange={e => setLabelField(e.target.value)}
                  onEnter={() => setLabel(labelField.trim())}
                />
              )}
              <PageOptions page={page} pageCount={pageCount} onChange={setPage} />
              <SortOptions columns={columns} sort={sort} onSort={setSort} />
              <SortDirToggle sortDir={sortDir} onToggle={toggleSortDir} />
              <ViewToggle view={view} onToggle={toggleView} />
            </>
          </div>
        </div>
        <AllLabelsButton visible={label.length > 0} onClick={() => setLabel('')} />
        <LabelSelected visible={label.length > 0} label={labelNameMapper(label)} />

        <div className="data-viewer2__viewer">{renderViewer()}</div>

        {pageCount > 1 && (
          <PageNumbers page={page} pageCount={pageCount} onChange={setPage} />
        )}
      </div>
    </div>
  );
};
