import {AnyAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import React, {useContext, useReducer} from 'react';
import {useLocation} from 'react-router';
import useSWR from 'swr';
import {
  DeploymentDetailsUrl,
  getDeploymentDetails,
} from '../../api/deployment/DeploymentDetails';
import {assertResponseBody} from '../../api/request';
import {StreamingDevice} from '../../types/deployment/StreamingDeviceResponse';
import {GspListItem} from '../../types/gsp/GspListItem';
import {TaskInfo} from '../../types/metadata/TaskInfo';
import {Parameter} from '../../types/model/framework/Parameter';
import {UpdateParameterRequest} from '../../api/postprocessor/UpdateParameters';
import {ImageSet} from '../../api/test/ImageSets';
import {ChatConnection} from '../Chat/Chat';
import {InputSource} from '../../types/deployment/InferenceInputSource';

export const TASK_STATUS = {
  IDLE: 'idle',
  IN_PROGRESS: 'inProgress',
  READY: 'ready',
  CONNECTED: 'connected',
  COMPLETE: 'complete',
  ERROR: 'error',
} as const;

export const INFERENCE_SOURCE_TEXT = new Map<InputSource, string>([
  ['WEB_CAMERA', 'Web Camera'],
  ['IP_CAMERA', 'Video Source'],
  ['FILE', 'Video File'],
  ['DEVICE_CAMERA', 'On Device'],
  ['APP_INPUT', "Application's Internal Source"],
  ['TEXT_INPUT', 'Chat'],
  ['IMAGES', 'Images'],
]);

export const TEST_USER_SOURCES = new Set<InputSource>([
  'IMAGES',
  'WEB_CAMERA',
  'IP_CAMERA',
  'FILE',
]);

export const VIDEO_SOURCES = new Set<InputSource>(['WEB_CAMERA', 'IP_CAMERA', 'FILE']);

export type TaskStatus = typeof TASK_STATUS[keyof typeof TASK_STATUS];

export type InferenceTask = {
  status: TaskStatus;
  uploadProgress: number;
  totalSize: number;
};

export type DeployTask = {
  status: TaskStatus;
  monitoringResponse: TaskInfo | null;
};

export type StreamingTask = {
  status: TaskStatus;
  userStream: MediaStream | null;
  remoteStream: MediaStream | null;
  receiveOnly: boolean;
  realtimePostProcessorParameters: Array<Parameter>;
  realtimePreProcessorParameters: Array<Parameter>;
  data: Record<string, any> | null;
};

export type RuntimeModeType = 'wasm' | 'webgl';

export const RUNTIME_MODE_OPTIONS: Record<RuntimeModeType, string> = {
  wasm: 'CPU (using WASM)',
  webgl: 'GPU (using WebGL)',
};

export type ONNXTask = {
  status: TaskStatus;
  id?: string;
  params?: Pick<
    DeployConfigState,
    | 'files'
    | 'inferenceSource'
    | 'ipCamera'
    | 'videoFile'
    | 'runtimeMode'
    | 'deploymentId'
  >;
};

export type TestSourceType =
  | 'VALIDATION'
  | 'TEST'
  | 'TRAIN'
  | 'USER_DATA'
  | 'IMAGE_SETS'
  | 'APP_INPUT'
  | 'TEXT_INPUT';
export type TestOutputType = 'modelStatistics' | 'sampleInference';

export type DeployConfigState = {
  name: string;
  description: string;
  deploymentId: string;
  postProcessorParams: Array<UpdateParameterRequest>;
  postProcessorRealtimeParams: Array<Parameter>;
  preProcessorParams: Array<UpdateParameterRequest>;
  preProcessorRealtimeParams: Array<Parameter>;
  testSource: TestSourceType | '';
  outputTypes: TestOutputType[];
  nodeId: string;
  files: File[];
  inferenceSource: InputSource | '';
  ipCamera: string;
  videoFile: string;
  isONNXRuntimeWeb: boolean;
  runtimeMode: RuntimeModeType;
  tiling: string;
  inferenceImageSetId?: string;
  environment: Record<string, string>;
};

type DeployState = {
  selectedDeploymentIds: string[];
  gspList: GspListItem[];
  streamingDevices: StreamingDevice[];
  imageSets: ImageSet[];
  isExportModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isAddDeviceModalOpen: boolean;
  isDuplicateModalOpen: boolean;
  pendingFiles: File[];
  defaultTestName: string;
  deployConfig: DeployConfigState;
  streamingTask: StreamingTask;
  inferenceTask: InferenceTask;
  deployTask: DeployTask;
  onnxTask: ONNXTask;
  signalingServerUrl: string;
  baseModel: string;
  chatConnection?: ChatConnection;
};

const initialInferenceTask = {
  status: TASK_STATUS.IDLE,
  uploadProgress: 0,
  totalSize: 0,
};

const initialDeployTask = {
  status: TASK_STATUS.IDLE,
  monitoringResponse: null,
};

const initialStreamingTask = {
  status: TASK_STATUS.IDLE,
  userStream: null,
  remoteStream: null,
  receiveOnly: false,
  realtimePostProcessorParameters: [],
  realtimePreProcessorParameters: [],
  data: null,
};

const initialONNXTask: ONNXTask = {
  status: TASK_STATUS.IDLE,
};

type InitialStateSettings = {
  deploymentId?: string;
  defaultTestName?: string;
  signalingServerUrl?: string;
  streamingDevices?: StreamingDevice[];
  imageSets?: ImageSet[];
  environment?: Record<string, string>;
  isONNXRuntimeWeb?: boolean;
};

function getInitialState({
  imageSets,
  environment,
  deploymentId,
  defaultTestName,
  streamingDevices,
  signalingServerUrl,
  isONNXRuntimeWeb = false,
}: InitialStateSettings): DeployState {
  return {
    selectedDeploymentIds: [],
    gspList: [],
    streamingDevices: streamingDevices || [],
    imageSets: imageSets || [],
    isExportModalOpen: false,
    isDeleteModalOpen: false,
    isAddDeviceModalOpen: false,
    defaultTestName: defaultTestName || '',
    isDuplicateModalOpen: false,
    pendingFiles: [],
    deployConfig: {
      name: defaultTestName || '',
      description: '',
      deploymentId: deploymentId || '',
      postProcessorParams: [],
      postProcessorRealtimeParams: [],
      preProcessorParams: [],
      preProcessorRealtimeParams: [],
      testSource: '',
      outputTypes: [],
      nodeId: '',
      files: [],
      inferenceSource: 'IMAGES',
      ipCamera: '',
      videoFile: '',
      runtimeMode: 'webgl',
      isONNXRuntimeWeb,
      tiling: '',
      environment: {...environment},
    },
    streamingTask: initialStreamingTask,
    inferenceTask: initialInferenceTask,
    deployTask: initialDeployTask,
    onnxTask: initialONNXTask,
    signalingServerUrl: signalingServerUrl || '',
    baseModel: '',
    chatConnection: undefined,
  };
}

const slice = createSlice({
  name: 'useDeploy',
  initialState: getInitialState({}) as DeployState,
  reducers: {
    setGSPList(state, action: PayloadAction<DeployState['gspList']>) {
      return {...state, gspList: action.payload};
    },
    setStreamingDevices(state, action: PayloadAction<DeployState['streamingDevices']>) {
      return {...state, streamingDevices: action.payload};
    },
    setImageSets(state, action: PayloadAction<DeployState['imageSets']>) {
      return {...state, imageSets: action.payload};
    },
    setDeployConfig(state, action: PayloadAction<Partial<DeployState['deployConfig']>>) {
      return {...state, deployConfig: {...state.deployConfig, ...action.payload}};
    },
    setIsDeleteModalOpen(state, action: PayloadAction<DeployState['isDeleteModalOpen']>) {
      return {...state, isDeleteModalOpen: action.payload};
    },
    setIsExportModalOpen(state, action: PayloadAction<DeployState['isExportModalOpen']>) {
      return {...state, isExportModalOpen: action.payload};
    },
    setIsAddDeviceModalOpen(
      state,
      action: PayloadAction<DeployState['isAddDeviceModalOpen']>
    ) {
      return {...state, isAddDeviceModalOpen: action.payload};
    },
    setIsDuplicateModalOpen(
      state,
      action: PayloadAction<DeployState['isDuplicateModalOpen']>
    ) {
      return {...state, isDuplicateModalOpen: action.payload};
    },
    setDeployTask(state, action: PayloadAction<Partial<DeployState['deployTask']>>) {
      return {...state, deployTask: {...state.deployTask, ...action.payload}};
    },
    setInferenceTask(
      state,
      action: PayloadAction<Partial<DeployState['inferenceTask']>>
    ) {
      return {...state, inferenceTask: {...state.inferenceTask, ...action.payload}};
    },
    setPendingFiles(state, action: PayloadAction<DeployState['pendingFiles']>) {
      return {...state, pendingFiles: action.payload};
    },
    setStreamingTask(
      state,
      action: PayloadAction<Partial<DeployState['streamingTask']>>
    ) {
      return {...state, streamingTask: {...state.streamingTask, ...action.payload}};
    },
    setONNXTask(state, action: PayloadAction<Partial<DeployState['onnxTask']>>) {
      return {...state, onnxTask: {...state.onnxTask, ...action.payload}};
    },
    setChatConnection(state, action: PayloadAction<DeployState['chatConnection']>) {
      return {...state, chatConnection: action.payload};
    },
    setSignalingServerUrl(
      state,
      action: PayloadAction<DeployState['signalingServerUrl']>
    ) {
      return {...state, signalingServerUrl: action.payload};
    },
    setBaseModel(state, action: PayloadAction<DeployState['baseModel']>) {
      return {...state, baseModel: action.payload};
    },
    setSelectedDeploymentIds(
      state,
      action: PayloadAction<DeployState['selectedDeploymentIds']>
    ) {
      return {...state, selectedDeploymentIds: action.payload};
    },
    resetDeployTask(state) {
      return {...state, deployTask: initialDeployTask};
    },
    resetInferenceTask(state) {
      return {...state, InferenceTask: initialInferenceTask};
    },
    resetStreamingTask(state) {
      return {...state, streamingTask: initialStreamingTask};
    },
    resetONNXTask(state) {
      return {...state, onnxTask: initialONNXTask};
    },
    resetAllTasks(state) {
      return {
        ...state,
        inferenceTask: initialInferenceTask,
        streamingTask: initialStreamingTask,
        deployTask: initialDeployTask,
        onnxTask: initialONNXTask,
      };
    },
    renameDuplicates(state) {
      const filesMap: Record<string, File[]> = {};
      const allFiles: File[] = [];

      for (const file of state.deployConfig.files) {
        filesMap[file.name] = [file];
        allFiles.push(file);
      }

      for (const file of state.pendingFiles) {
        const duplicateFiles = filesMap[file.name] ?? [];
        const count = duplicateFiles.length;
        let newFile = file;

        if (count > 0) {
          for (let i = count + 1; true; i++) {
            const match = /(.*)\.([^.]*)$/.exec(file.name);
            const name = match?.[1];
            const ext = match?.[2];
            const newName = match ? `${name} (${i}).${ext}` : `${file.name} (${i})`;
            if (!filesMap[newName]) {
              newFile = new File([file], newName);
              break;
            }
          }
        }

        filesMap[file.name] = [...duplicateFiles, newFile];
        allFiles.push(newFile);
      }

      return {
        ...state,
        deployConfig: {
          ...state.deployConfig,
          files: allFiles,
        },
        pendingFiles: [],
      };
    },
    resetDeploy(state) {
      return getInitialState({
        defaultTestName: state.defaultTestName,
        signalingServerUrl: state.signalingServerUrl,
        streamingDevices: state.streamingDevices,
        imageSets: state.imageSets,
        environment: state.deployConfig.environment,
        isONNXRuntimeWeb: state.deployConfig.isONNXRuntimeWeb,
      });
    },
    setDefaultTestName(state, action: PayloadAction<DeployState['defaultTestName']>) {
      return {
        ...state,
        defaultTestName: action.payload,
        deployConfig: {...state.deployConfig, name: action.payload},
      };
    },
  },
});

const DeployStateContext = React.createContext<DeployState>(getInitialState({}));
const DeployDispatchContext = React.createContext<React.Dispatch<AnyAction> | null>(null);

export const TestDeployProvider = ({children}: {children: React.ReactNode}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const deploymentId = searchParams.get('deploymentId') ?? '';
  const [state, dispatch] = useReducer(slice.reducer, getInitialState({deploymentId}));

  return (
    <DeployStateContext.Provider value={state}>
      <DeployDispatchContext.Provider value={dispatch}>
        {children}
      </DeployDispatchContext.Provider>
    </DeployStateContext.Provider>
  );
};

export const useDeploy = () => {
  const state = useContext(DeployStateContext);
  const dispatch = useContext(DeployDispatchContext);

  if (!state || !dispatch) {
    throw new Error('useDeploy must be used within a TestDeployProvider component.');
  }

  return [state, dispatch] as const;
};

export function useDeploymentDetails(id?: string) {
  const {data = null} = useSWR(
    id ? [DeploymentDetailsUrl, id] : null,
    () => (id ? assertResponseBody(getDeploymentDetails({deploymentId: id})) : null),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return data;
}

export const {
  renameDuplicates,
  resetAllTasks,
  resetDeployTask,
  resetInferenceTask,
  resetStreamingTask,
  setBaseModel,
  setDefaultTestName,
  setDeployConfig,
  setDeployTask,
  setGSPList,
  setInferenceTask,
  setIsAddDeviceModalOpen,
  setIsExportModalOpen,
  setIsDuplicateModalOpen,
  setPendingFiles,
  setSignalingServerUrl,
  setIsDeleteModalOpen,
  setStreamingDevices,
  setImageSets,
  setStreamingTask,
  setSelectedDeploymentIds,
  setONNXTask,
  resetONNXTask,
  resetDeploy,
  setChatConnection,
} = slice.actions;
