import React, {useCallback} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {DownloadFileIcon} from './DownloadFileIcon';
import './DownloadEdgeInstallerDialog.scss';
import {StudioRadioGroup} from '../../../base-components/StudioRadio';
import {Link} from 'react-router-dom';
import ViewExpandIcon from '../../../assets/icons/ViewExpandIcon';
import URL from '../../../config/url';
import axios from 'axios';
import {toast} from '../../../base-components/StudioToast';
import {downloadFileFromURL} from '../../../utils';

export type DownloadEdgeInstallerDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

type EdgeInstallerScriptType = 'standard' | 'air_gapped';

type EdgeInstallerScriptResponse = {
  body: {
    type: EdgeInstallerScriptType;
    downloadUrl: string;
  };
};

export const DownloadEdgeInstallerDialog = ({
  isOpen,
  onClose,
}: DownloadEdgeInstallerDialogProps) => {
  const intl = useIntl();
  const [value, setValue] = React.useState<string>('STANDARD');
  const options = {
    STANDARD: intl.formatMessage({id: 'download.edge.installer.type.standard.title'}),
    AIR_GAPPED: intl.formatMessage({id: 'download.edge.installer.type.air-gapped.title'}),
  };
  const optionsHelp = {
    STANDARD: intl.formatMessage({
      id: 'download.edge.installer.type.standard.description',
    }),
    AIR_GAPPED: intl.formatMessage({
      id: 'download.edge.installer.type.air-gapped.description',
    }),
  };
  const handleClose = useCallback(() => {
    setValue('STANDARD');
    onClose();
  }, [onClose]);

  const handleDownload = async () => {
    try {
      const {data} = await axios.get<EdgeInstallerScriptResponse>(
        URL.EDGE_INSTALLER_DOWNLOAD(value)
      );
      downloadFileFromURL({url: data.body.downloadUrl});
      handleClose();
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <Dialog
      open={isOpen}
      data-testid="download-edge-installer-dialog"
      className="download-edge-installer-dialog"
    >
      <DialogTitle className="download-edge-installer-dialog-title">
        <Typography>
          <FormattedMessage id="download.edge.installer.title" />
          <IconButton
            onClick={handleClose}
            data-testid="deploy-export-close-button"
            className="download-edge-installer-dialog_download-edge-installer-close-button"
          >
            <Close />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className="download-edge-installer-dialog_download-icon">
          <DownloadFileIcon></DownloadFileIcon>
        </div>
        <DialogContentText>
          <FormattedMessage id="download.edge.installer.body1" />
        </DialogContentText>
        <DialogContentText>
          <FormattedMessage id="download.edge.installer.body2" />
        </DialogContentText>
        <StudioRadioGroup
          label=""
          options={options}
          optionsHelpText={optionsHelp}
          RadioGroupProps={{value}}
          onChangeCapture={event => {
            setValue((event.target as HTMLInputElement).value);
            event.stopPropagation();
          }}
        />
        <div className="download-edge-installer-link">
          <Link
            to={'/guide?topicId=Appendix_A_Studio_Edge_Installation'}
            target={'_blank'}
          >
            {intl.formatMessage({id: 'download.edge.installer.more-info-link-title'})}{' '}
          </Link>
          <ViewExpandIcon className="download-edge-installer-dialog_link-icon" />
        </div>
      </DialogContent>
      <DialogActions>
        <FormButton
          buttonRole="secondary"
          value={intl.formatMessage({id: 'download.edge.installer.cancel.title'})}
          onClick={handleClose}
        />
        <FormButton
          type="submit"
          buttonRole="primary"
          value={intl.formatMessage({id: 'download.edge.installer.download.title'})}
          onClick={handleDownload}
        />
      </DialogActions>
    </Dialog>
  );
};
