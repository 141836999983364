import React from 'react';
import {IconButton, IconButtonProps} from '@material-ui/core';
import {ArrowUpwardRounded, ArrowDownwardRounded} from '@material-ui/icons';

import {SortDir} from './reducer';

type SortDirToggleProps = {
  sortDir: SortDir;
  onToggle: IconButtonProps['onClick'];
};

export function SortDirToggle(props: SortDirToggleProps) {
  return (
    <IconButton onClick={props.onToggle} className="data-viewer2__icon-button">
      {props.sortDir === 'asc' ? <ArrowUpwardRounded /> : <ArrowDownwardRounded />}
    </IconButton>
  );
}
