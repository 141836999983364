import React from 'react';
import {IconButton, IconButtonProps} from '@material-ui/core';
import {ViewListRounded, ViewComfyRounded} from '@material-ui/icons';

import {ViewId} from './reducer';

type ViewToggleProps = {
  view: ViewId;
  onToggle: IconButtonProps['onClick'];
};

export function ViewToggle(props: ViewToggleProps) {
  return (
    <IconButton onClick={props.onToggle} className="data-viewer2__icon-button">
      {props.view === 'grid' ? <ViewListRounded /> : <ViewComfyRounded />}
    </IconButton>
  );
}
