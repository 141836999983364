import axios, {AxiosResponse} from 'axios';

/**
 * Downloads a file with the specified name, MIME type, and content (supports concurrency donwloads).
 * @param fileName - The name of the file to be downloaded.
 * @param mimeType - The MIME type of the file.
 * @param content - The content of the file.
 */
export async function downloadFile(
  fileName: string,
  mimeType: string,
  content: string
): Promise<void> {
  const blob = new Blob([content], {type: mimeType});
  const url = window.URL.createObjectURL(blob);

  await downloadBlob({fileName, blobUrl: url});
}

type DownloadBlobOptions = {
  fileName: string;
  blobUrl: string;
  bubbleUpError?: boolean;
};

export async function downloadBlob({
  blobUrl,
  fileName,
  bubbleUpError = false,
}: DownloadBlobOptions): Promise<void> {
  try {
    const response: AxiosResponse<Blob> = await axios.get(blobUrl, {
      responseType: 'blob',
    });

    const urlCreator = window.URL || window.webkitURL;
    const fileUrl = urlCreator.createObjectURL(response.data);

    const tag = document.createElement('a');
    tag.href = fileUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();

    document.body.removeChild(tag);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);

    if (bubbleUpError) {
      throw error;
    }
  }
}

export type DownloadFileOptions = {
  url: string;
  showDownloadWindow?: boolean;
  fileName?: string;
};
export const downloadFileFromURL = ({
  url,
  showDownloadWindow,
  fileName,
}: DownloadFileOptions) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  if (showDownloadWindow) {
    if (fileName) {
      a.setAttribute('download', fileName);
    } else {
      // defaults to the name of the file from the url.
      a.setAttribute('download', '');
    }
  }
  a.setAttribute('href', url);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
