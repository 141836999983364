import React, {useContext, useEffect, useReducer, useState} from 'react';
import Hotkeys from 'react-hot-keys';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ls, {remove as lsRemove} from 'local-storage';
import {AnnotatorView} from './AnnotatorView';
import {ImageThumbView} from './ImageThumbView';
import {
  AnnotationType,
  CategoryType,
  DataPrepareTypeStr,
  ImageType,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {BoxLabelProps} from '../../../base-components/StudioAnnotator/types';
import {LS_KEY} from '../../../config/constants';
import {AnnotatorProvider} from '../../../base-components/StudioAnnotator/Provider';
import {DATASET_SUB_OP as OP} from '../../../config/url';
import {Toolbar, Box} from '@material-ui/core';
import {PageListContext} from './PageListProvider';
import {PageListNavigation} from './PageListNavigation';
import {Navigation} from '../Navigation';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {getOriginUrl} from '../helpers';
import {getPredictions, startAutoLabel, stopAutoLabel} from '../../../store/iat';
import {useAppDispatch, RootState} from '../../../store';
import {useSelector} from 'react-redux';
import {BOX_LABEL_PREDICTION} from '../../../base-components/StudioAnnotator/const';
import {flatten, forEach, values} from 'lodash';
import './DetectionPrepare.scss';
import {Alert} from '@material-ui/lab';
import {useIntl} from 'react-intl';
import {mergeAnnotations} from '../../../store/dataPrepare';

const lsData: any = ls(LS_KEY.DATA_DETECTION);
export type DetectionAnnotationDictionary = {
  [imageId: string]: BoxLabelProps[];
};

type AnnotationActiveChanges = {
  imageId: string;
  annotation: BoxLabelProps;
};
type ReducerState = {
  projectId: string;
  selectedImage: null | undefined | ImageType;
  annotations: DetectionAnnotationDictionary;
  activeChanges: AnnotationActiveChanges | undefined;
};

const saveToLocalStorage = (state: ReducerState) => ls(LS_KEY.DATA_DETECTION, state);
const resetLocalStorage = () => lsRemove(LS_KEY.DATA_DETECTION);

const initialState: ReducerState = {
  projectId: lsData?.projectId || '',
  selectedImage: lsData?.selectedImage || null,
  annotations: {},
  activeChanges: undefined,
};

const viewerSlice = createSlice({
  name: 'dataset/detection',
  initialState,
  reducers: {
    setProjectId(state, action: PayloadAction<string>) {
      state.projectId = action.payload;
      saveToLocalStorage(state);
    },
    setSelectedImage(state, action: PayloadAction<ImageType | null | undefined>) {
      state.selectedImage = action.payload;
      saveToLocalStorage(state);
    },
    setAnnotations(state, action: PayloadAction<DetectionAnnotationDictionary>) {
      state.annotations = action.payload;
    },
    setActiveChanges(state, action: PayloadAction<AnnotationActiveChanges>) {
      state.activeChanges = action.payload;
    },
    reset(state) {
      state.selectedImage = null;
      resetLocalStorage();
    },
  },
});

const rawAnnotationsToDictionary = (
  rawAnnotations: AnnotationType[],
  labels: CategoryType[],
  isPrediction: boolean = false
) => {
  const dict: DetectionAnnotationDictionary = {};
  rawAnnotations.forEach(el => {
    if (el.id !== null && el.id !== undefined) {
      !dict[el.image_id] && (dict[el.image_id] = []);
      const box = el?.bbox || [];
      dict[el.image_id].push({
        id: el.id.toString(),
        label: labels.find(label => label.id === el.category_id)?.name,
        x: box[0],
        y: box[1],
        width: box[2],
        height: box[3],
        style: isPrediction ? BOX_LABEL_PREDICTION : undefined,
      });
    }
  });
  return dict;
};

export type DetectionPrepareProps = {
  projectId: string;
  datasetId?: string;
  images?: ImageType[];
  labels?: CategoryType[];
  labelsMap?: {
    [key: string]: CategoryType;
  };
  annotations?: undefined | AnnotationType[];
  disableForward: boolean;
  disableBack: boolean;
  origin: DataPreparationOrigin;
  onChange?: (
    imageId: number,
    annotations: BoxLabelProps[],
    newPrediction?: boolean
  ) => void;
  onForward: (
    mergedPredictionAnnotations: AnnotationType[],
    discardedPredictionAnnotations: AnnotationType[]
  ) => void;
  onBack: () => void;
  onSelectionChange: (imageId: number) => void;
};

export const DetectionPrepare = ({
  images,
  labels = [],
  labelsMap = {},
  projectId,
  datasetId,
  annotations: rawAnnotations,
  disableForward,
  disableBack,
  onChange: onLocalChange = () => {},
  onSelectionChange = () => {},
  origin,
  onForward = () => {},
  onBack,
}: DetectionPrepareProps) => {
  const [state, dispatch] = useReducer(viewerSlice.reducer, initialState);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [batchPredictionLoaded, setBatchPredictionLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const {selectedImage, projectId: lsProjectId, annotations} = state;
  const {setSelectedImage, setProjectId, setAnnotations, reset} = viewerSlice.actions;
  const {
    page,
    setPage,
    itemsPerPageCount,
    totalListCount,
    listContent,
    sortedList,
  } = useContext(PageListContext);
  const autoLabelReady = useSelector((state: RootState) => state.iat.autoLabelReady);
  const isIATBusy = useSelector((state: RootState) => state.iat.isBusy);
  const isAutoLabel = useSelector((state: RootState) => state.dataPrepare.isAutoLabel);
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );
  const intl = useIntl();

  const appDispatch = useAppDispatch();

  // Start/Stop Autolabel
  useEffect(() => {
    if (projectId && datasetId && isAutoLabel && !autoLabelReady && !isIATBusy) {
      appDispatch(
        startAutoLabel({
          projectId,
          resourceId: datasetId,
          type: DataPrepareTypeStr.DETECTION,
        })
      );
    }
    return () => {
      autoLabelReady &&
        annotationData &&
        appDispatch(stopAutoLabel({projectId, resourceId: datasetId}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLabelReady, projectId, isAutoLabel, appDispatch, datasetId]);

  // Set default image selection
  useEffect(() => {
    if (sortedList.length) {
      if (selectedImage) {
        // set to local storage saved data
        setSelectedIndex(sortedList?.findIndex(i => i.data.id === selectedImage?.id));
      } else {
        // set to first image if there is none
        dispatch(setSelectedImage(sortedList[0].data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedList, selectedImage]);

  // Change the page based on the selected image
  useEffect(() => {
    if (page > 0 && selectedIndex >= 0 && itemsPerPageCount > 0) {
      const pageBySelectedIndex = Math.floor(selectedIndex / itemsPerPageCount) + 1;
      page !== pageBySelectedIndex && setPage(pageBySelectedIndex);
    }
  }, [selectedIndex, page, itemsPerPageCount, totalListCount, setPage]);

  useEffect(() => {
    if (!!projectId && lsProjectId !== projectId) {
      dispatch(reset());
      dispatch(setProjectId(projectId));
    }
    return () => {
      dispatch(reset());
    };
  }, [projectId, setProjectId, lsProjectId, reset]);

  useEffect(() => {
    if (!!labels.length && !!rawAnnotations?.length && !isDataLoaded) {
      setIsDataLoaded(true);
      dispatch(setAnnotations(rawAnnotationsToDictionary(rawAnnotations, labels)));
    }
  }, [rawAnnotations, labels, isDataLoaded]);

  // Fetch bulk predictions for images with no annotations
  useEffect(() => {
    const itemCount = itemsPerPageCount || 0;
    const startIndex = (page - 1) * itemCount;
    const list = sortedList?.slice(startIndex, startIndex + itemCount) || listContent;
    const ids: number[] = list.map(el => el.data.id);
    if (
      autoLabelReady &&
      isAutoLabel &&
      list.length &&
      !batchPredictionLoaded &&
      selectedImage &&
      ids.includes(selectedImage.id)
    ) {
      const nIDs: number[] = ids.filter(id => {
        const imageAnnotations = annotations[id];
        return !imageAnnotations || !imageAnnotations.length;
      });
      getAutoLabels(nIDs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLabelReady, isAutoLabel, listContent, page, selectedImage, itemsPerPageCount]);

  // Get predictions per image in case the image has no annotations
  useEffect(() => {
    if (!!selectedImage && isAutoLabel && batchPredictionLoaded) {
      const imageAnnotations = annotations[selectedImage.id];
      if (!imageAnnotations || !imageAnnotations.length) {
        getAutoLabels([selectedImage.id]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  // Stage changes on currently selected image predictions
  // This way it will be saves on next save iteration
  useEffect(() => {
    if (
      batchPredictionLoaded &&
      hasPredictions(selectedImage && annotations[selectedImage?.id])
    ) {
      selectedImage && onLocalChange(selectedImage.id, annotations[selectedImage.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchPredictionLoaded]);

  useEffect(() => {
    const keys = Object.keys(annotations);
    for (let i = 0; i < keys.length; i++) {
      const val = annotations[keys[i]];
      if (val.some(el => !!el.label)) {
        setIsValid(true);
        return;
      }
    }
    setIsValid(false);
  }, [annotations]);

  const hasPredictions = (labels: BoxLabelProps[] | undefined | null) =>
    labels ? labels.some(el => !!el.style) : false;

  const getAutoLabels = async (ids: number[]) => {
    const annotationCount = flatten(values(annotations)).length;
    if (ids.length && !!annotationCount && !isIATBusy) {
      await appDispatch(
        getPredictions({
          projectId,
          resourceId: datasetId,
          ids,
          samples: ids?.length,
        })
      ).then(({payload}) => {
        // Since annotations are treated as temp/in-memory data only and not persistent at the BE. We need to differentiate them from manual annotations
        // Later on the ids will be mapped from the backend. This a temp data handling
        const data: AnnotationType[] = payload.body.annotations.map((el: any) => ({
          ...el,
          id: Math.floor(Math.random() * 100000),
        }));
        if (data && data.length) {
          dispatch(
            setAnnotations({
              ...annotations,
              ...rawAnnotationsToDictionary(data, labels, true),
            })
          );
        }

        setBatchPredictionLoaded(true);
      });
    }
  };

  const onChange = (updatedAnnotations: BoxLabelProps[]) => {
    const newAnnotations = {...annotations};
    if (selectedImage) {
      newAnnotations[selectedImage.id] = updatedAnnotations;
      dispatch(setAnnotations(newAnnotations)); //updates local
      onLocalChange(selectedImage.id, updatedAnnotations); //saves to BE
    }
  };

  const finalizeAnnotations = (imageId: number | null | undefined) => {
    if (typeof imageId === 'number') {
      const imageAnnotations = annotations[imageId] || null;
      if (imageAnnotations && imageAnnotations.length) {
        hasPredictions(imageAnnotations) && onLocalChange(imageId, imageAnnotations);
        const updatedAnnotations = imageAnnotations.map(el => ({
          ...el,
          style: undefined,
        }));
        dispatch(
          setAnnotations({
            ...annotations,
            [imageId]: updatedAnnotations,
          })
        ); //updates local
      }
    }
  };

  // image clicks navigation
  const setSelection = (image: ImageType) => {
    const index = sortedList?.findIndex(i => i.data.id === image?.id);
    index !== undefined && changeSelectedImage(index);
  };

  // hotkey image navigation
  const changeSelectedImage = (index: number, finalize: boolean = true) => {
    const image = sortedList[index].data;
    if (image) {
      selectedImage?.id !== image.id &&
        finalize &&
        finalizeAnnotations(selectedImage?.id);
      dispatch(setSelectedImage(image));
      setSelectedIndex(index);
      onSelectionChange(image.id);
    }
  };

  const prevImage = () =>
    changeSelectedImage(selectedIndex - 1 >= 0 ? selectedIndex - 1 : 0);

  const nextImage = () => {
    const imageCount = sortedList?.length || 0;
    changeSelectedImage(
      selectedIndex + 1 < imageCount ? selectedIndex + 1 : imageCount - 1
    );
  };

  const verifyAnnotations = async () => {
    const annots: AnnotationType[] = [];
    const imageIds: {id: number}[] = [];
    const ids: number[] = listContent.map(el => el.data.id);
    const newAnnotations: DetectionAnnotationDictionary = {};
    for (let i = 0; i < ids.length; i++) {
      const annotation = annotations[ids[i]];
      const entries: AnnotationType[] = [];
      (annotation || [])?.map(value => {
        if (value.style !== undefined) {
          entries.push({
            id: parseInt(value.id) as any,
            image_id: ids[i],
            category_id:
              value.label && labelsMap[value.label]
                ? Number(labelsMap[value.label].id)
                : undefined,
            bbox: [value.x, value.y, value.width, value.height],
          });
        }
        return;
      });
      if (entries.length) {
        annots.push(...entries);
        imageIds.push({id: ids[i]});
        const removedAnnotsStyle = annotation.map(e => ({...e, style: undefined}));
        newAnnotations[ids[i]] = removedAnnotsStyle;
      }
    }
    if (annots.length) {
      await appDispatch(
        mergeAnnotations({
          resourceId: datasetId!,
          images: imageIds,
          annotations: annots,
        })
      );
      dispatch(
        setAnnotations({
          ...annotations,
          ...newAnnotations,
        })
      );
    }
  };

  const onPageChange = async (page: number) => {
    // Since we are using pagination component. Page number is always guaranteed to be valid
    // Always select the first item on the page when using pagination
    setBatchPredictionLoaded(false);
    await verifyAnnotations();
    changeSelectedImage((page - 1) * itemsPerPageCount, false);
  };

  const onSelect = (selections: BoxLabelProps[]) => {
    if (selections.length && selectedImage) {
      const ids: string[] = selections.map(el => el.id);
      dispatch(
        setAnnotations({
          ...annotations,
          [selectedImage?.id]: annotations[selectedImage?.id].map((el: BoxLabelProps) => {
            return ids.includes(el.id) ? {...el, style: undefined} : el;
          }),
        })
      );
    }
  };

  const onSubmit = () => {
    const mergedPredictionsAnnotations: AnnotationType[] = [];
    const discardedPredictionsAnnotations: AnnotationType[] = [];

    forEach(annotations, (boxLabelArr, imageId) => {
      boxLabelArr.forEach(boxLabel => {
        const annotation: AnnotationType = {
          id: Number(boxLabel.id),
          image_id: Number(imageId),
          category_id: boxLabel.label
            ? labels.find(label => label.name === boxLabel.label)?.id
            : undefined,
          bbox: [boxLabel.x, boxLabel.y, boxLabel.width, boxLabel.height],
        };
        mergedPredictionsAnnotations.push(annotation);
        !boxLabel.style && discardedPredictionsAnnotations.push(annotation);
      });
    });
    onForward(mergedPredictionsAnnotations, discardedPredictionsAnnotations);
  };

  const hotKey: {[key: string]: Function} = {
    right: nextImage,
    left: prevImage,
  };

  return (
    <>
      <Hotkeys keyName="right,left" onKeyUp={(keyName: string) => hotKey[keyName]()}>
        <div className="detection-view">
          <Toolbar
            className="data-prepare__header detection-view__header"
            variant="dense"
          >
            <Box className="detection-view__pagination">
              <PageListNavigation onChange={onPageChange} />
            </Box>
            <Box className="detection-view__container">
              {isAutoLabel && (
                <Alert severity="warning" className="detection-view__alert">
                  {intl.formatMessage({id: 'dataPrep.detection.assistedLabel'})}
                </Alert>
              )}
            </Box>
            <Navigation
              onForward={onSubmit}
              onBack={onBack}
              disableForward={disableForward || !isValid}
              disableBack={disableBack}
            />
          </Toolbar>
          <div className="detection-view__content">
            <AnnotatorProvider>
              <ImageThumbView
                images={images}
                onClick={setSelection}
                selection={selectedImage}
                projectId={projectId}
                origin={origin}
                annotations={annotations}
              />
              <AnnotatorView
                image={selectedImage}
                src={
                  selectedImage && sortedList?.length
                    ? `${getOriginUrl(origin)(projectId, OP.GET_BY_NAME)}?name=${
                        selectedImage?.file_name
                      }`
                    : ''
                }
                labels={labels.map(el => ({
                  id: el.id,
                  label: el.name,
                }))}
                annotations={selectedImage ? annotations[selectedImage.id] : undefined}
                onChange={onChange}
                onSelect={onSelect}
              />
            </AnnotatorProvider>
          </div>
        </div>
      </Hotkeys>
    </>
  );
};
