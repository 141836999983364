import React from 'react';
import {useHistory} from 'react-router';
import {Button} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';

export function BackButton() {
  const history = useHistory();

  return (
    <Button
      size="large"
      variant="contained"
      onClick={history.goBack}
      className="data-viewer2__back-button"
    >
      <FormattedMessage id="dataViewer.returnToStage" />
    </Button>
  );
}
