import {useIntl} from 'react-intl';
import React from 'react';
import {TextFieldProps} from '@material-ui/core';

import {StudioSearch} from './../../../base-components/StudioSearch';

type LabelSearchProps = {
  labelField: string;

  onEnter(): void;
  onChange: TextFieldProps['onChange'];
};

export const LabelSearch = (props: LabelSearchProps) => {
  const intl = useIntl();

  return (
    <div className="data-viewer2__search">
      <StudioSearch
        value={props.labelField}
        placeholder={intl.formatMessage({id: 'dataViewer.search'})}
        onKeyUp={event => {
          if (event.key === 'Enter') {
            props.onEnter();
          }
        }}
        onChange={props.onChange}
      />
    </div>
  );
};
