import * as queryString from 'query-string';
import {DataPreparationOrigin} from '../types/dataset/DataPreparationContainer';
import {DatasetDistributionRequest} from '../types/dataset/DatasetDistributionRequest';
import {DatasetGetAnnotationsRequest} from '../types/dataset/DatasetGetAnnotationsRequest';
import {DatasetGetByFilenamesRequest} from '../types/dataset/DatasetGetByFilenamesRequest';
import {DatasetGetByIdRequest} from '../types/dataset/DatasetGetByIdRequest';
import {DatasetGetByNameRequest} from '../types/dataset/DatasetGetByNameRequest';
import {DatasetImageRequest} from '../types/dataset/DatasetImageRequest';
import {DatasetLabelsRequest} from '../types/dataset/DatasetLabelsRequest';
import {DatasetListRequest} from '../types/dataset/DatasetListRequest';
import {DatasetThumbnailsFromMarketplaceRequest} from '../types/dataset/DatasetThumbnailsFromMarketplaceRequest';
import {ProjectListRequest} from '../types/project/ProjectListRequest';
import {DeploymentDataDriftRequest} from '../types/deployment/DataDriftRawSnapshot';

type QueryStringParameters = Parameters<typeof queryString['stringifyUrl']>;

const stringifyUrl = (
  object: QueryStringParameters[0],
  options?: QueryStringParameters[1]
) =>
  queryString.stringifyUrl(object, {
    skipEmptyString: true,
    ...options,
  });

const URL = {
  LOGIN: '/studio/login',
  FORGOT_PASSWORD: '/studio/user/resetPassword',
  RESET_PASSWORD: '/studio/user/confirmPasswordResetting',
  ACTIVATE_ACCOUNT: '/studio/user/activateAccount',
  RESEND_ACTIVATION: '/studio/user/resendActivationToken',
  PROJECT_CREATE: '/studio/project/new',
  CONFIG: '/studio/operation/config',
  PROJECT_GET: (id: string) => `/studio/project/${id}/get`,
  PROJECT_OPEN: (id: string) => `/studio/project/${id}/open`,
  PROJECT_UPDATE: (id: string) => `studio/project/${id}/update`,
  PROJECT_IMPORT: `/studio/project/import`,
  PROJECT_IMPORT_FINALIZE: `/studio/project/import/finalize`,
  PROJECT_DEPLOYMENTS: '/studio/project/deployments/list',
  PROJECT_LIST: (query: ProjectListRequest) =>
    stringifyUrl({url: '/studio/project/list', query}),
  PROJECT_LITE_LATEST: `/studio/project/lite/get`,
  STAGE_STATUS: (id: string) => `/studio/operation/${id}/stages/list`,
  PROJECT_OP_STATUS: (id: string) =>
    id ? `/studio/operation/${id}/status` : `/studio/operation/status`,
  CA_UPDATE: '/studio/operation/ca/update',
  CA_NOTIFY: '/studio/operation/ca/notify',
  CA_FEEDBACK: '/studio/operation/ca/feedback',
  DATASET: (id: string, op: string) => `/studio/dataset/${id}/${op}`,
  DATASET_OP: (op: string, subOp: string) => `/studio/dataset/${op}/${subOp}`,
  DATASET_DISTRIBUTION: (query: DatasetDistributionRequest) =>
    stringifyUrl({url: '/studio/dataset/distribution', query}),
  DATASET_DEPLOYMENT: (id: string, op: string) =>
    `/studio/dataset/${id}/deployment/${op}`,
  DATASET_RAW: (id: string, op: string) => `/studio/dataset/${id}/raw/${op}`,
  DATASET_DATASET: (id: string, op: string) => `/studio/dataset/${id}/dataset/${op}`,
  DATASET_OP_RAW: (op: string, subOp: string) => `/studio/dataset/${op}/raw/${subOp}`,
  DATASET_FIND: '/studio/dataset/find',
  DATASET_GET_ANNOTATIONS: (id: string, query: DatasetGetAnnotationsRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/get_annotations`, query}),
  DATASET_GET_BY_FILENAMES: (id: string, query: DatasetGetByFilenamesRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/getbyfilenames`, query}),
  DATASET_GET_BY_ID: (id: string, query: DatasetGetByIdRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/getbyid`, query}),
  DATASET_GET_BY_NAME: (id: string, query: DatasetGetByNameRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/getbyName`, query}),
  DATASET_IMAGE: (id: string, query: DatasetImageRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/image`, query}),
  DATASET_LABELS: (id: string, query: DatasetLabelsRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/labels`, query}),
  DATASET_LIST: (id: string, query: DatasetListRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/list`, query}),
  DATASET_SET_ANNOTATIONS: (id: string, query: DatasetGetAnnotationsRequest) =>
    stringifyUrl({url: `/studio/dataset/${id}/apply_raw_dataset`, query}),
  DATASET_SPLIT: '/studio/dataset/split',
  DATASET_SPLIT_CLARIFY: '/studio/dataset/split/clarify',
  DATASET_SPLIT_FINALIZE: '/studio/dataset/split/finalize',
  DATASET_TILING_INITIATE: (projectId: string) =>
    `/studio/dataset/tiling/initiate?projectId=${projectId}`,
  DATASET_TILING_CLARIFY: '/studio/dataset/tiling/clarify',
  DATASET_TILING_FINALIZE: (projectId: string) =>
    `/studio/dataset/tiling/finalize?projectId=${projectId}`,
  DATASET_THUMBNAILS_FROM_MARKETPLACE: (query: DatasetThumbnailsFromMarketplaceRequest) =>
    stringifyUrl({url: '/studio/dataset/thumbnailsFromMarketplace', query}),
  DATASET_MERGE: (projectId: string) => `/studio/dataset/${projectId}/merge`,
  DATASET_MERGE_FINALIZE: (projectId: string) =>
    `/studio/dataset/${projectId}/merge/finalize`,
  DATASET_MERGE_REVERT: (projectId: string) =>
    `/studio/dataset/${projectId}/merge/revert`,
  DATASET_PREPARE_EDIT: '/studio/dataset/prepare/edit',
  DATASET_PREPARE_DDA: '/studio/dataset/prepare/dda',
  DATASET_PREPARE_DDA_FINALIZE: '/studio/dataset/prepare/dda/finalize',
  DATASET_PREP_RESUME: (projectId: string, context: DataPreparationOrigin) =>
    `/studio/dataset/${projectId}/${context}/preparation/resume`,
  DEVICE_LIST: '/studio/device/list',
  DEVICE_NEW: '/studio/device/new',
  DEVICE_UPD: '/studio/device/update',
  DATASET_SUMMARY: (id: string) => `/studio/dataset/${id}/summary`,
  FEATURE_CHECK: (feature: string) => `/studio/feature/check/${feature}`,
  EDGE_INSTALLER_DOWNLOAD: (type: string) => `/studio/edge/installer/${type}/download`,
  MODEL: (op: string) => `/studio/model/${op}`,
  MODEL_OP: (op: string, subOp: string) => `/studio/model/${op}/${subOp}`,
  MODEL_DOWNLOAD_ONNX: (projectId: string) => `/studio/model/${projectId}/onnx`,
  MODEL_DETAILS: '/studio/model/details',
  GSP_DOWNLOAD: ({deploymentId, projectId}: {deploymentId: string; projectId: string}) =>
    `/studio/application/download?projectId=${projectId}&deploymentId=${deploymentId}`,
  APPLICATION_DELETE: ({
    applicationId,
    projectId,
  }: {
    applicationId: string;
    projectId: string;
  }) =>
    `/studio/gsp/application/delete?projectId=${projectId}&applicationId=${applicationId}`,
  MODEL_DELETE: (id: string) => `/studio/model/delete?projectId=${id}`,
  PROJECT_DELETE: (id: string) => `/studio/project/${id}/delete`,
  INTEGRATION: (type: string, id: string, query: string) =>
    `/studio/integration/${type}/${id}/${query}`,
  INTEGRATION_TYPES: '/studio/integration/types',
  INTEGRATION_NEW: '/studio/integration/new',
  INTEGRATION_NEW_FINALIZE: '/studio/integration/finalize/new',
  INTEGRATION_FLATTENED_LIST: (role: string) => `/studio/integration/${role}/list`,
  INTEGRATION_EDIT: (id: string) => `/studio/integration/update/${id}`,
  INTEGRATION_EDIT_FINALIZE: (id: string) => `/studio/integration/finalize/${id}/update`,
  INTEGRATION_DELETE: (id: string) => `/studio/integration/remove/${id}`,
  INTEGRATION_GET: '/studio/integration/get',
  INTEGRATION_LIST_BY_ROLE: (role: string) => `/studio/integration/${role}/list`,
  MARKETPLACE_FILES: `/studio/marketplace/files`,
  MARKETPLACE_PUBLISH: '/studio/marketplace/publish',
  MARKETPLACE_PUBLISH_CLARIFY: '/studio/marketplace/publish/clarify',
  MARKETPLACE_PUBLISH_FINALIZE: '/studio/marketplace/publish/finalize',
  MARKETPLACE_SOLUTIONS_CATEGORIES: '/studio/marketplace/solution/categoriesList',
  MARKETPLACE_ITEM_DELETE: '/studio/marketplace/delete',
  MODEL_BUILD: '/studio/model/build',
  MODEL_BUILD_CLARIFY: '/studio/model/build/clarify',
  MODEL_BUILD_FINALIZE: '/studio/model/build/finalize',
  MODEL_IMPORT_SELECT: '/studio/model/import/select',
  MODEL_IMPORT_FINALIZE: '/studio/model/import/finalize',
  MODEL_IMPORT: (type: string) => `/studio/model/import/${type}`,
  MODEL_CONVERT: '/studio/model/convert',
  MODEL_CONVERT_CLARIFY: '/studio/model/convert/clarify',
  MODEL_CONVERT_FINALIZE: '/studio/model/convert/finalize',
  MODEL_FILE_PREPARE: '/studio/model/file/prepare',
  MODEL_FILE_PREPARE_CLARIFY: '/studio/model/file/prepare/clarify',
  MODEL_FILE_PREPARE_FINALIZE: '/studio/model/file/prepare/finalize',
  MODEL_FIND: '/studio/model/find',
  MODEL_FINETUNE: '/studio/model/finetune',
  MODEL_FINETUNE_CLARIFY: '/studio/model/finetune/clarify',
  MODEL_FINETUNE_FINALIZE: '/studio/model/finetune/finalize',
  MODEL_OPTIMIZE: '/studio/model/optimize',
  MODEL_OPTIMIZE_CLARIFY: '/studio/model/optimize/clarify',
  MODEL_OPTIMIZE_FINALIZE: '/studio/model/optimize/finalize',
  MODEL_RETRAIN: `/studio/model/retrain`,
  MODEL_RETRAIN_CLARIFY: `/studio/model/retrain/clarify`,
  MODEL_RETRAIN_FINALIZE: `/studio/model/retrain/finalize`,
  MODEL_VALIDATE: `/studio/model/validate`,
  MODEL_VALIDATE_FINALIZE: `/studio/model/validate/finalize`,
  MODEL_CREATE_DEPLOYMENT: `/studio/model/createDeployment`,
  MODEL_CREATE_DEPLOYMENT_CLARIFY: `/studio/model/createDeployment/clarify`,
  MODEL_CREATE_DEPLOYMENT_FINALIZE: `/studio/model/createDeployment/finalize`,
  NETDEPLOY_VERSION: '/studio/operation/netdeploy/version',
  TEMPLATE_CREATE: '/studio/template/create',
  TEMPLATE_CREATE_CONFIRM: '/studio/template/create/clarify',
  TEMPLATE_CREATE_FINALIZE: '/studio/template/create/finalize',
  PREPROCESSOR_OP: (op: string, subOp: string) => `/studio/preprocessor/${op}/${subOp}`,
  POSTPROCESSOR_OP: (op: string, subOp: string) => `/studio/postprocessor/${op}/${subOp}`,
  OPERATION: (op: string) => `/studio/operation/${op}`,
  OPERATION_CANCEL: (op: string) => `/studio/operation/${op}/cancel`,
  LOGOUT: '/studio/logout',
  APPLICATIONS_GET: '/studio/deployment/applications',
  NODES_GET: '/studio/deployment/nodes',
  COMPATIBLE_NODES_GET: '/studio/deployment/nodes/compatible',
  INITIATE_DEPLOYMENT: `/studio/deployment/initiate`,
  FINALIZE_DEPLOYMENT: `/studio/deployment/finalize`,
  START_DEPLOYMENT: `/studio/deployment/start`,
  RESTART_DEPLOYMENT: `/studio/deployment/restart`,
  STOP_DEPLOYMENT: `/studio/deployment/stop`,
  DELETE_DEPLOYMENT: `/studio/deployment/delete`,
  DEPLOYMENT_DASHBOARD: `/studio/deployment/dashboard/list`,
  DEPLOYMENT_SHORT_LIST: `/studio/deployment/short/list`,
  DEPLOYMENT_TEST_LIST: `/studio/deployment/test/list`,
  INFERENCE_APPLICATIONS: 'studio/deployment/inference/applications',
  DEPLOYMENT_DETAILS: `/studio/deployment/details`,
  DEPLOYMENT_DATA_DRIFT: (request: DeploymentDataDriftRequest) =>
    `/studio/deployment/data-drift?deploymentId=${request.deploymentId}&pageSize=${request.pageSize}&pageIndex=${request.pageIndex}`,
  IAT_INIT: (projectId: string) => `/studio/iat/${projectId}/initialize`,
  IAT_SHUTDOWN: (projectId: string) => `/studio/iat/${projectId}/shutdown`,
  IAT_PREDICTIONS: (projectId: string) => `/studio/iat/${projectId}/predictions`,
  UPLOAD_VIDEO_FILEMAN: '/fileman/upload',
  USER_DETAILS: '/studio/user/details',
  RETRIEVE_USER_TOKEN: '/studio/user/token/retrieve',
  CREATE_USER_TOKEN: '/studio/user/token/create',
  DOWNLOAD_LOG: '/studio/operation/maintenance/logs',
  DELETE_POST_PROCESSOR: (projectId: string, id: string) =>
    `/studio/postprocessor/delete/${projectId}/${id}`,
  DELETE_PRE_PROCESSOR: (projectId: string, id: string) =>
    `/studio/preprocessor/delete/${projectId}?preProcessorId=${id}`,
};

export const DATASET_OP = {
  IMPORT: 'import',
  THUMBNAIL: 'thumbnails',
  GET_ANNOTATIONS: 'get_annotations',
  SET_ANNOTATIONS: 'apply_raw_dataset',
  SAVE_ANNOTATIONS: 'save_annotations',
  LABELING_START: 'labeling/start',
  LABELING_APPLY: 'labeling/apply',
  MERGE_ANNOTATIONS: 'merge_annotations',
  MERGE_CATEGORIES: 'merge_categories',
};
export const DATASET_SUB_OP = {
  FINALIZE: 'finalize',
  CLOUD: 'cloud',
  CLARIFY: 'clarify',
  FILESYSTEM: 'filesystem',
  GET_BY_ID: 'getbyid',
  GET_BY_FILE_NAMES: 'getbyfilenames',
  GET_BY_NAME: 'getbyName',
  OPERATION_CANCEL: (type: string) => `/studio/operation/${type}/cancel`,
};

export const MODEL_OP = {
  IMPORT: 'import',
  DELETE: 'delete',
  PREPARE_HELPER: 'helper/prepare',
  CONVERT: 'convert',
  OPTIMIZE: 'optimize',
  VALIDATE: 'validate',
  FINE_TUNE: 'finetune',
  CREATE_DEPLOYMENT: 'createDeployment',
};

export const MODEL_SUB_OP = {
  CLOUD: 'cloud',
  COUNT: 'count',
  GET_BY_ID: 'getbyid',
  FINALIZE: 'finalize',
  CLARIFY: 'clarify',
  FILESYSTEM: 'filesystem',
};

export const ISP_OP = {
  IMPORT: 'import',
  NEW: 'new',
  UPDATE: 'update',
};

export const ISP_SUB_OP = {
  FINALIZE: 'finalize',
  CLOUD: 'cloud',
  CLARIFY: 'clarify',
  FILESYSTEM: 'filesystem',
};

export default URL;
