import React from 'react';
import {SelectProps} from '@material-ui/core';

import {getPageOptions} from './utils';
import {StudioSelect} from './../../../base-components/StudioSelect';

type PageOptionsProps = {
  page: number;
  pageCount: number;
  onChange(page: number): void;
};

export function PageOptions(props: PageOptionsProps) {
  const options = getPageOptions(props.pageCount);

  return (
    <StudioSelect
      options={options}
      order={Object.keys(options)}
      SelectProps={{
        value: props.page,
        onChange: (event: Parameters<NonNullable<SelectProps['onChange']>>[0]) => {
          props.onChange(Number(event.target.value as string));
        },
      }}
    />
  );
}
