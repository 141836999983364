import React, {useEffect, useRef} from 'react';
import {throttle} from 'lodash';

const THROTTLE_DURATION_MS = 300;
const BUFFER_PX = 200;

export const StudioInfiniteScroll = ({hasMore, onLoadMore, children, ...rest}) => {
  const containerRef = useRef();
  const sentinelRef = useRef();
  const isLoadingMoreRef = useRef(false);

  useEffect(() => {
    const container = containerRef.current;
    const sentinel = sentinelRef.current;

    const setIsLoadingMore = value => (isLoadingMoreRef.current = value);

    if (
      !isLoadingMoreRef.current &&
      hasMore &&
      container.clientHeight > sentinel.getBoundingClientRect().top
    ) {
      setIsLoadingMore(true);
      onLoadMore(() => setIsLoadingMore(false));
      return;
    }

    const checkWindowScroll = throttle(() => {
      if (
        isLoadingMoreRef.current ||
        !hasMore ||
        sentinel.getBoundingClientRect().top - container.clientHeight > BUFFER_PX
      ) {
        return;
      }

      setIsLoadingMore(true);
      onLoadMore(() => setIsLoadingMore(false));
    }, THROTTLE_DURATION_MS);

    container.addEventListener('scroll', checkWindowScroll);
    container.addEventListener('resize', checkWindowScroll);

    return () => {
      container.removeEventListener('scroll', checkWindowScroll);
      container.removeEventListener('resize', checkWindowScroll);
    };
  }, [hasMore, onLoadMore]);

  return (
    <div ref={containerRef} {...rest}>
      {children}
      <div ref={sentinelRef} />
    </div>
  );
};
