import {useReducer, useCallback} from 'react';
import {bindActionCreators, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit';

import {DataSplitType} from './../../../types/dataset/Dataset';
import {DatasetListRequest} from './../../../types/dataset/DatasetListRequest';

export type ViewId = 'grid' | 'list';
export type SortId = DatasetListRequest['sort'];
export type SortDir = DatasetListRequest['sortDir'];

type ReducerState = {
  page: number;
  label: string;
  sort: SortId;
  view: ViewId;
  sortDir: SortDir;
  labelField: string;
  splitType?: DataSplitType;
};

const initialState: ReducerState = {
  page: 1,
  label: '',
  view: 'grid',
  sort: 'label',
  labelField: '',
  sortDir: 'asc',
  splitType: 'TRAIN',
};

function reverseSort(sortDir: SortDir) {
  return sortDir === 'asc' ? 'desc' : 'asc';
}

const viewerSlice = createSlice({
  name: 'dataset/viewer',
  initialState,
  reducers: {
    setLabel(state, action: PayloadAction<string>) {
      state.label = action.payload;
      state.labelField = action.payload;
      state.page = 1;
    },
    setLabelField(state, action: PayloadAction<string>) {
      state.labelField = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSort(state, action: PayloadAction<SortId>) {
      state.sort = action.payload;
      state.page = 1;
    },
    setSplitType(state, action: PayloadAction<DataSplitType | undefined>) {
      state.splitType = action.payload;
      state.page = 1;
    },
    toggleSortDir(state) {
      state.sortDir = reverseSort(state.sortDir);
      state.page = 1;
    },
    toggleSortHeading(state, action: PayloadAction<SortId>) {
      if (action.payload === state.sort) {
        state.sortDir = reverseSort(state.sortDir);
      } else {
        state.sort = action.payload;
      }
      state.page = 1;
    },
    toggleView(state) {
      state.view = state.view === 'grid' ? 'list' : 'grid';
      if (state.sort === 'label') {
        state.page = 1;
      }
    },
  },
});

export function useDataViewer() {
  const [state, dispatch] = useReducer(viewerSlice.reducer, initialState);

  const actions = useCallback(
    () => bindActionCreators(viewerSlice.actions, dispatch as Dispatch),
    [dispatch]
  );

  return {
    ...state,
    ...actions(),
  };
}
