import axios from 'axios';
import {push} from 'connected-react-router';
import querystring from 'query-string';

import {toast} from '../../base-components/StudioToast';
import ERROR from '../../config/error';
import URL from '../../config/url';

// ACTIONS
export const PROJECT_PENDING = 'project/pending';
export const PROJECT_PENDING_STATUS = 'project/pending-status';
export const PROJECT_SUCCESS = 'project/success';
export const PROJECT_ERROR = 'project/error';
export const PROJECT_CREATE = 'project/create';
export const PROJECT_CREATE_SUCCESS = 'project/create-success';
export const PROJECT_DELETED = 'project/deleted';
export const PROJECT_UPDATE_DEPLOYMENT = 'project/update/deployment';
export const PROJECT_FROM_TEMPLATE = 'project/from-template';
export const PROJECT_CANCEL = 'project/cancel';

// ACTION CREATORS

export const projectPending = payload => ({
  type: PROJECT_PENDING,
  payload,
});

export const projectPendingStatus = status => ({
  type: PROJECT_PENDING_STATUS,
  payload: status,
});

export const projectSuccess = payload => ({
  type: PROJECT_SUCCESS,
  payload,
});

export const projectError = payload => ({
  type: PROJECT_ERROR,
  payload,
});

export const projectFetch = (id, open = false, show_error = true) => {
  // project_open and project_get work the same way, except project_get
  // does not result in a notification being sent to CA
  const url = open ? URL.PROJECT_OPEN(id) : URL.PROJECT_GET(id);
  return dispatch => {
    dispatch(projectPending());

    return axios
      .get(url)
      .then(res => dispatch(projectSuccess(res.data)))
      .catch(error => {
        if (show_error) {
          handleError(dispatch, ERROR.PROJECT_LOAD_FAILED(), projectError(error));
        }
      });
  };
};

export const projectUpdate = (id, name, description, logLevel) => {
  return dispatch =>
    axios
      .post(URL.PROJECT_UPDATE(id), {name, description, logLevel})
      .then(res => dispatch(projectSuccess(res.data)))
      .catch(error =>
        handleError(dispatch, ERROR.PROJECT_UPDATE_FAILED(), projectError(error))
      );
};

export const projectDelete = id => {
  return () => {
    return axios.get(URL.PROJECT_DELETE(id));
  };
};

export const projectCreate = ({
  description,
  name,
  dataset,
  model,
  isp,
  pp,
  solution,
  mode,
}) => {
  return async dispatch => {
    if (solution) {
      dispatch({
        type: PROJECT_FROM_TEMPLATE,
        payload: {description, name, solution, mode},
      });
    } else {
      try {
        dispatch(projectPending());
        const {data} = await axios.post(URL.PROJECT_CREATE, {description, name});
        dispatch(projectSuccess(data));
        dispatch(
          push(
            querystring.stringifyUrl({
              url: `/project/${data.body.id}/upload`,
              query: buildQuery({dataset, model, isp, pp}),
            })
          )
        );
      } catch (error) {
        handleError(dispatch, ERROR.PROJECT_CREATE_FAILED(name), projectError(error));
      }
    }
  };
};

function buildQuery({dataset, model, isp, pp}) {
  const query = {};

  if (dataset) {
    query.datasetId = dataset.integrationId;
    query.datasetName = dataset.name;
    query.datasetType = dataset.type;
  }

  if (model) {
    query.modelId = model.integrationId;
    query.modelName = model.name;
    query.modelType = model.type;
  }

  if (isp) {
    query.ispId = isp.integrationId;
    query.ispName = isp.name;
    query.ispType = isp.type;
  }

  if (pp) {
    query.ppId = pp.integrationId;
    query.ppName = pp.name;
    query.ppType = pp.type;
  }

  return query;
}

function handleError(dispatch, msg, error) {
  toast.error(msg);
  dispatch(error);
}
