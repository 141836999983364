import axios from 'axios';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import {Formik, useField} from 'formik';
import React, {useState, useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';

import URL from '../../../config/url';
import ERROR from '../../../config/error';
import {formikErrors} from '../../Formik/formikErrors';
import {useLoginSettings} from '../../../api/login/settings';
import {
  AuthError,
  AuthPageBody,
  AuthPageForm,
  AuthPageFooter,
  AuthPageHeader,
  AuthPageContainer,
  AuthPageEmailField,
  AuthPageSubmitButton,
  AuthPagePasswordField,
  AuthPageSecondaryButton,
} from '..';

import './Login.scss';

const validationSchema = Yup.object({
  email: Yup.string().required(ERROR.FIELD_REQUIRED()),
  password: Yup.string().required(ERROR.FIELD_REQUIRED()),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login(props) {
  const intl = useIntl();
  const history = useHistory();
  const {state} = useLocation();
  const [error, setError] = useState(null);

  const {data, error: errorGettingLoginSettings} = useLoginSettings();
  const areEmailRelatedControlsEnable = useMemo(
    () => (errorGettingLoginSettings || !data ? true : data.useEmail),
    [data, errorGettingLoginSettings]
  );

  return (
    <AuthPageContainer>
      <Formik
        initialValues={initialValues}
        initialErrors={formikErrors(validationSchema, initialValues)}
        onSubmit={async fields => setError(await login(fields, state?.from))}
        validationSchema={validationSchema}
      >
        <AuthPageForm>
          <AuthPageHeader>
            <FormattedMessage id="auth.login" />
          </AuthPageHeader>
          <AuthPageBody>
            {props.activated && (
              <p>
                <FormattedMessage id="auth.loginSuccess" />
              </p>
            )}
            <AuthError error={error} />
            <AuthPageEmailField name="email" data-testid="email-input" />
            <LoginLink to="/resend-activation">
              {areEmailRelatedControlsEnable && (
                <FormattedMessage id="auth.notActivatedLink" />
              )}
            </LoginLink>
            <AuthPagePasswordField name="password" data-testid="password-input" />
            <LoginLink to="/forgot-password">
              {areEmailRelatedControlsEnable && (
                <FormattedMessage id="auth.forgotPassword" />
              )}
            </LoginLink>
          </AuthPageBody>
          <AuthPageFooter>
            <div>
              {areEmailRelatedControlsEnable && (
                <AuthPageSecondaryButton
                  disabled={!areEmailRelatedControlsEnable}
                  value={intl.formatMessage({id: 'auth.signUp'})}
                  onClick={() => history.push('/signup')}
                />
              )}
            </div>
            <AuthPageSubmitButton value={intl.formatMessage({id: 'auth.login'})} />
          </AuthPageFooter>
        </AuthPageForm>
      </Formik>
    </AuthPageContainer>
  );
}

function LoginLink({children, to}) {
  const [emailField] = useField('email');
  const params = new URLSearchParams();

  if (emailField.value) {
    params.set('emailAddress', emailField.value);
  }

  return (
    <Link className="login__link" to={{pathname: to, search: params.toString()}}>
      {children}
    </Link>
  );
}

async function login(fields, from) {
  try {
    const res = await axios.post(
      URL.LOGIN,
      {
        name: fields.email,
        password: fields.password,
      },
      {headers: {bypassDefaultErrorHandler: true}}
    );

    if (res.data.errors) {
      return res.data.errors;
    }

    if (from && from.pathname) {
      window.location.replace(`${from.pathname}${from.search}`);
    } else {
      window.location.replace('/?wizard=true');
    }
    return null;
  } catch (err) {
    return err;
  }
}
