import {isNumber} from 'lodash';
import {connect} from 'react-redux';
import {Dialog, DialogActions, DialogContent, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import * as Yup from 'yup';
import FormButton from '../../../base-components/StudioButton/FormButton';
import {StudioTextField} from '../../../base-components/StudioTextField';
import {toast} from '../../../base-components/StudioToast';
import STUDIO_URL from '../../../config/url';
import {
  setIsAddDeviceModalOpen,
  setStreamingDevices,
  TASK_STATUS,
  useDeploy,
  INFERENCE_SOURCE_TEXT,
} from '../useDeploy';
import {getVideoStream, EVENT_TYPE, VideoStream} from '../VideoStream';
import {useSelector} from 'react-redux';
import {FileExplorer} from '../FileExplorer';
import {DropArea} from '../../../base-components/StudioDropArea';
import Waiting from '../../../base-components/StudioWaiting/Waiting';
import {TestRegionSelection} from '../TestRegionSelection';
import {fetchUserToken} from '../../SettingsView/UserDetails';
import {includesProtocolPrefix, isYoutubeLink} from '../../../utils/url';
import './AddStreamingDeviceForm.scss';

const convertPointsToRelative = (origWidth, origHeight, points) => {
  return points
    ? points.map(point => ({
        x: point.x / origWidth,
        y: point.y / origHeight,
      }))
    : [];
};

const convertBoxToPoints = (origWidth, origHeight, data) => {
  // top left corner
  const r1X = data.x / origWidth;
  const r1Y = data.y / origHeight;
  // lower right corner
  const r2X = (data.x + data.width) / origWidth;
  const r2Y = (data.y + data.height) / origHeight;
  return [
    {x: r1X, y: r1Y},
    {x: r2X, y: r1Y},
    {x: r2X, y: r2Y},
    {x: r1X, y: r1Y},
  ];
};

const AddStreamingBaseDeviceForm = ({className = '', onAddedAsset, userId}) => {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewConfig, setPreviewConfig] = useState();
  const [previewStatus, setPreviewStatus] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const [videoMetadata, setVideoMetadata] = useState();
  const [regions, setRegions] = useState();
  const [{streamingDevices, isAddDeviceModalOpen, deployConfig}, dispatch] = useDeploy();

  const serverConfig = useSelector(state => state.config);
  const [dimWidth, dimHeight] = [800, 480];
  const [existingDevice, setExistingDevice] = useState(null);

  const MAX_FILE_UPLOAD_MB = serverConfig?.maxVideoUploadSize;

  const sourceOptions = {
    IP_CAMERA: INFERENCE_SOURCE_TEXT.get('IP_CAMERA'),
    FILE: INFERENCE_SOURCE_TEXT.get('FILE'),
  };

  const {start, stop} = useMemo(getVideoStream, []);

  useEffect(() => {
    if (isAddDeviceModalOpen) {
      setIsSubmitting(false);
    }
  }, [isAddDeviceModalOpen]);

  useEffect(
    function startPreviewStream() {
      if (previewConfig && previewConfig.url && previewConfig.source) {
        const handleEvent = event => {
          switch (event.type) {
            case EVENT_TYPE.RECEIVE_ONLY_READY:
              setPreviewStatus(TASK_STATUS.READY);
              break;
            case EVENT_TYPE.ERROR:
              setPreviewStatus(TASK_STATUS.ERROR);
              break;
            case EVENT_TYPE.CONNECTED:
              setPreviewStatus(TASK_STATUS.CONNECTED);
              break;
            case EVENT_TYPE.REMOTE_STREAM:
              setRemoteStream(event.stream);
              break;
            case EVENT_TYPE.STREAM_FINISHED:
              setPreviewStatus(TASK_STATUS.COMPLETE);
              break;
            default:
              break;
          }
        };

        //TODO: display monitoring events (FPS, BPS)
        start({
          receiveOnly: true,
          source: previewConfig.source,
          url: previewConfig.url,
          onEvent: handleEvent,
          serverConfig,
          studioTaskId: null,
          monitorConfig: {
            monitorStream: true,
            onMonitoringEvent: monitor => {
              //todo: display data
              console.log(`Bitrate: ${monitor.kbps} kbps`);
              console.log(`Frame rate: ${monitor.fps} fps`);
            },
          },
        });
        return stop;
      }
    },
    [previewConfig, serverConfig, start, stop]
  );

  useEffect(
    function stopStreamIfModalClosed() {
      if (!isAddDeviceModalOpen) {
        stop();
      }
    },
    [isAddDeviceModalOpen, stop]
  );

  const getEmbeddedYoutubeURL = async url => {
    try {
      const {data} = await axios.get(
        `https://www.youtube.com/oembed?format=json&url=${url}`
      );
      const {html} = data;
      setPreviewConfig({
        html,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const uploadFile = async uploadedFile => {
    try {
      let token = await fetchUserToken();
      const buffer = await uploadedFile.arrayBuffer();
      const response = await axios.put(STUDIO_URL.UPLOAD_VIDEO_FILEMAN, buffer, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/octet-stream',
          Authorization: `Basic ${userId}:${token}`,
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  const packageRegion = (region, isBox = false) => {
    if (region == null) {
      return [];
    }
    return region.map(el => {
      return {
        name: el.label,
        points: isBox
          ? convertBoxToPoints(
              videoMetadata ? videoMetadata.clientWidth : 800,
              videoMetadata ? videoMetadata.clientHeight : 480,
              el
            )
          : convertPointsToRelative(
              videoMetadata ? videoMetadata.clientWidth : 800,
              videoMetadata ? videoMetadata.clientHeight : 480,
              el.points
            ),
      };
    });
  };

  const addNewDevice = async (name, source, url) => {
    try {
      const amend = existingDevice != null;
      const {data} = await axios.post(
        amend ? STUDIO_URL.DEVICE_UPD : STUDIO_URL.DEVICE_NEW,
        {
          id: existingDevice?.id || null,
          name,
          type: source,
          url,
          lines: packageRegion(regions?.lines),
          regions: [
            ...packageRegion(regions?.polygons),
            // convert box labels to Point coordinates
            ...packageRegion(regions?.boxes, true),
          ],
        }
      );
      if (data.body) {
        const device = data.body;
        const devices = streamingDevices.filter(x => x.id !== device.id);
        dispatch(setStreamingDevices([...devices, device]));
        const msg = amend ? 'added' : 'updated';
        toast.success(`Successfully ${msg} streaming input: ${name}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async values => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const {name, source} = values;
      let url;

      if (source === 'IP_CAMERA') {
        url = values.url;
      } else if (source === 'FILE') {
        const {uploadedFile} = values;

        url = await uploadFile(uploadedFile);
      }

      if (url) {
        await addNewDevice(name, source, url);
      }

      dispatch(setIsAddDeviceModalOpen(false));
      setPreviewConfig(null);
      setExistingDevice(null);
      setRegions({lines: [], polygons: []});

      onAddedAsset && onAddedAsset(name, url);
    }
  };

  const handleCancel = () => {
    setPreviewConfig(null);
    setExistingDevice(null);
    setRegions({lines: [], polygons: []});
    dispatch(setIsAddDeviceModalOpen(false));
  };

  const handleVideoLoadedMetadata = metadata => {
    setVideoMetadata(metadata);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'stream.nameError'})),
    url: Yup.string().when('source', {
      is: 'IP_CAMERA',
      then: Yup.string()
        .required(intl.formatMessage({id: 'stream.urlRequired'}))
        .test('url-test', intl.formatMessage({id: 'stream.urlInvalid'}), value => {
          try {
            new URL(value);
            return true;
          } catch (e) {
            return false;
          }
        })
        .test(
          'valid-protocol-prefix',
          intl.formatMessage({
            id: 'test.addStreamingDevice.invalidUrlPrefix',
          }),
          value => includesProtocolPrefix(value)
        ),
    }),
    uploadedFile: Yup.mixed().when('source', {
      is: 'FILE',
      then: Yup.mixed().required(intl.formatMessage({id: 'stream.fileRequired'})),
    }),
  });
  let device = streamingDevices.find(x => x.id === deployConfig.ipCamera);
  const amendSelection = (x, i) => {
    const pnts = x.points.map(({x, y}) => {
      return {x: x * dimWidth, y: y * dimHeight};
    });
    let label = x.name || '';
    label = /^(Line|Region)\s+\d+$/.test(label) ? null : label;
    const defLbl = (x?.points?.length === 2 ? 'Line ' : 'Region ') + i;

    const defaultObject = {
      id: (x?.points?.length === 2 ? 'LINE_' : 'POLY_') + i,
      label: label || defLbl,
      complete: true,
    };
    return {...defaultObject, ...x, points: pnts};
  };
  if (isAddDeviceModalOpen && device && !previewConfig?.url) {
    setPreviewConfig({
      source: 'IP_CAMERA',
      url: device.url,
    });
    setRegions({
      lines: device.lines?.map(amendSelection),
      polygons: device.regions?.map(amendSelection),
    });
    setExistingDevice(device);
  }

  return (
    <Dialog
      open={isAddDeviceModalOpen}
      className={`${className} add-streaming-device`}
      fullWidth
      maxWidth="xl"
    >
      <Formik
        initialValues={{
          name: device ? device.name : '',
          source: deployConfig.inferenceSource,
          uploadedFile: null,
          url: device ? device.url : '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({values, isValid, handleReset}) => (
          <Form className="add-streaming-device__form">
            <h2 className="add-streaming-device__title">
              <FormattedMessage id="stream.configure" />
            </h2>
            <DialogContent className="add-streaming-device__form-body">
              <Grid container>
                <Grid xs={12} lg={3}>
                  <Field name="name">
                    {({field, meta}) => (
                      <StudioTextField
                        required
                        name="name"
                        className="add-streaming-device__field"
                        label="Name"
                        error={meta.touched && meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ''}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="source">
                    {({field, meta}) => (
                      <StudioTextField
                        {...field}
                        required
                        disabled
                        error={meta.touched && meta.error}
                        value={sourceOptions[field.value]}
                        className="add-streaming-device__field"
                        helperText={meta.touched && meta.error ? meta.error : ''}
                        label={
                          <span className="add-streaming-device__field__label">
                            {intl.formatMessage({id: 'test.addStreamingDeviceTypeLabel'})}
                          </span>
                        }
                      />
                    )}
                  </Field>
                  <Field name="url">
                    {({field, meta, form}) =>
                      form.values.source === 'IP_CAMERA' && (
                        <>
                          <StudioTextField
                            required
                            label="URL"
                            className="add-streaming-device__field"
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error ? meta.error : ''}
                            {...field}
                            onChange={(...args) => {
                              setPreviewConfig(null);
                              field.onChange(...args);
                            }}
                            onBlur={(...args) => {
                              if (!meta.error) {
                                if (isYoutubeLink(values.url)) {
                                  getEmbeddedYoutubeURL(values.url);
                                } else {
                                  setPreviewConfig({
                                    source: values.source,
                                    url: values.url,
                                  });
                                }
                              }
                              field.onBlur(...args);
                            }}
                          />
                        </>
                      )
                    }
                  </Field>
                </Grid>
                <Grid xs={12} lg={9} className="add-streaming-device__preview_container">
                  <Field name="url">
                    {({field, meta, form}) =>
                      form.values.source === 'IP_CAMERA' && (
                        <div className="add-streaming-device__preview">
                          {(!previewConfig || meta.error) && <video />}
                          {previewConfig?.url && !meta.error && previewConfig.url && (
                            <div className="add-streaming-device__video_container">
                              <TestRegionSelection
                                regions={regions}
                                onChange={setRegions}
                                status={
                                  previewStatus !== TASK_STATUS.CONNECTED
                                    ? previewStatus
                                    : null
                                }
                                source={() => (
                                  <VideoStream
                                    receiveOnly
                                    remoteStream={remoteStream}
                                    onLoadedMetadata={handleVideoLoadedMetadata}
                                  />
                                )}
                              />
                            </div>
                          )}
                          {previewConfig?.html && !meta.error && (
                            <div
                              className="add-streaming-device__iframe-preview"
                              dangerouslySetInnerHTML={{__html: previewConfig.html}}
                            />
                          )}
                        </div>
                      )
                    }
                  </Field>
                  <Field name="uploadedFile">
                    {({field, meta, form}) =>
                      form.values.source === 'FILE' && (
                        <>
                          <div className="add-streaming-device__field">
                            <DropArea
                              message={intl.formatMessage(
                                {
                                  id: 'stream.videoFileUpload',
                                },
                                {
                                  size: MAX_FILE_UPLOAD_MB,
                                }
                              )}
                              onChange={files => {
                                const file = files[0];
                                if (
                                  isNumber(MAX_FILE_UPLOAD_MB) &&
                                  file.size > MAX_FILE_UPLOAD_MB * 1000000
                                ) {
                                  toast.error(
                                    `Max file size of ${MAX_FILE_UPLOAD_MB}MB exceeded`
                                  );
                                } else {
                                  form.setFieldValue('uploadedFile', files[0]);
                                }
                              }}
                              accept="video/*"
                            />
                            <FileExplorer
                              files={field.value ? [field.value] : []}
                              onDelete={() => form.setFieldValue('uploadedFile', null)}
                              onClear={() => form.setFieldValue('uploadedFile', null)}
                              size="small"
                              icon={isSubmitting ? 'check' : 'delete'}
                            />
                          </div>
                          {isSubmitting && (
                            <Waiting diameter={40} loadingText="Uploading..." />
                          )}
                        </>
                      )
                    }
                  </Field>
                  {/* TODO: Add later  */}
                  {/* <SupportedVideoTypes /> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="add-streaming-device__actions">
              <FormButton
                type="button"
                buttonRole="secondary"
                value="Cancel"
                disabled={isSubmitting}
                onClick={() => {
                  handleReset();
                  handleCancel();
                }}
              />
              <FormButton
                type="submit"
                buttonRole="primary"
                value="Submit"
                disabled={!isValid || isSubmitting} // This has been commented and I did not find the reason why
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  userId: state.user.userId,
});

export const AddStreamingDeviceForm = connect(mapStateToProps)(
  AddStreamingBaseDeviceForm
);

PropTypes.AddStreamingDeviceForm = {
  className: PropTypes.string,

  onAddedAsset: PropTypes.func.isRequired,
};
