import React from 'react';
import {Chip, IconButton, Radio} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import cn from 'classnames';
import Image from 'material-ui-image';
import HumanReadableTime from '../../../base-components/StudioHumanReadableTime/HumanReadableTime';
import URL from '../../../config/url';
import {
  IntegrationPurpose,
  IntegrationRole,
} from '../../../types/integrations/IntegrationResponse';
import CubePlaceholderIcon from '../../../assets/icons/CubePlaceholderIcon';
import {MarketplaceResult} from '../../../types/marketplace/MarketplaceFilesResponse';
import {ModelPlaceholderIcon} from '../../../assets/icons/ModelPlaceholderIcon';
import {ISPPlaceholderIcon} from '../../../assets/icons/ISPPlaceholderIcon';
import fileSize from 'filesize';
import {FormattedMessage} from 'react-intl';
import {getImageCount, metadataMap} from '../metadataMap';
import './MarketplaceGridItem.scss';

const THUMBNAIL_GRID_COLUMNS = 4;
const THUMBNAIL_GRID_ROWS = 1;
const THUMBNAIL_HEIGHT = 60;
const THUMBNAIL_WIDTH = 60;

type MarketplaceGridItemProps = {
  className?: string;
  item: MarketplaceResult;
  compatibleDatasetsMap: Map<string, MarketplaceResult>;
  purpose: IntegrationPurpose;
  role: IntegrationRole;
  isSelected?: boolean;
  onSelect?: () => void;
  onDetailsClick?: () => void;
};

const TAG_LIMIT = 3;
const DESC_CHAR_LIMIT = 80;

export const MarketplaceTagList = ({
  tags,
  limit,
  size = 'large',
}: {
  tags: string[];
  limit?: number;
  size?: 'small' | 'large';
}) => {
  return (
    <div
      className={cn(
        'marketplace-tag-list',
        size === 'small' && 'marketplace-tag-list--small'
      )}
    >
      {tags.slice(0, limit).map(tag => (
        <Chip
          key={tag}
          className="marketplace-tag-list__tag"
          clickable={false}
          label={tag}
        />
      ))}
      {limit != null && tags.length > limit && (
        <span className="marketplace-tag-list__more-tags">
          {`+${tags.length - limit}`}
        </span>
      )}
    </div>
  );
};

export const MarketplaceItemPlaceholder = ({
  purpose,
  ...rest
}: {purpose: IntegrationPurpose} & React.HTMLAttributes<SVGElement>) => {
  switch (purpose) {
    case 'MODEL_STORAGE':
      return <ModelPlaceholderIcon {...rest} />;
    case 'ISP_STORAGE':
      return <ISPPlaceholderIcon {...rest} />;
    case 'PP_STORAGE':
      return <ISPPlaceholderIcon {...rest} />;
    case 'DATASET_STORAGE':
    default:
      return <CubePlaceholderIcon {...rest} />;
  }
};

const MarketplaceGridItemMetadata = ({
  label,
  children,
}: {
  label?: string;
  children?: React.ReactNode;
}) => {
  return children ? (
    <div className="marketplace-grid-item__field">
      {label && (
        <div
          className={cn('marketplace-grid-item--small', 'marketplace-grid-item--light')}
        >
          <FormattedMessage id={label} />
        </div>
      )}
      <div
        className={cn('marketplace-grid-item__value', 'marketplace-grid-item--medium')}
      >
        {children}
      </div>
    </div>
  ) : null;
};

const MarketplaceGridItemRow = ({
  children,
  className,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return children ? (
    <div className={cn('marketplace-grid-item__row', className)}>{children}</div>
  ) : null;
};

export const MarketplaceGridItem = ({
  className,
  isSelected = false,
  item,
  purpose,
  role,
  compatibleDatasetsMap,
  onSelect,
  onDetailsClick,
}: MarketplaceGridItemProps) => {
  const handleDetailsClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    onDetailsClick && onDetailsClick();
  };

  const renderResourceDetails = (purpose: IntegrationPurpose) => {
    if (purpose === 'DATASET_STORAGE') {
      return (
        <>
          <MarketplaceGridItemMetadata label="marketplaceResult.size">
            {item.metadata.size != null && (
              <div className="marketplace-grid-item--truncated">
                {fileSize(item.metadata.size)}
              </div>
            )}
          </MarketplaceGridItemMetadata>
          <MarketplaceGridItemMetadata label="marketplaceResult.images">
            {item.metadata.labelDistribution != null && (
              <div className="marketplace-grid-item--truncated">
                {getImageCount(item.metadata.labelDistribution)}
              </div>
            )}
          </MarketplaceGridItemMetadata>
          <MarketplaceGridItemMetadata label="marketplaceResult.dataLoader">
            {item.metadata.dataLoader != null && (
              <div className="marketplace-grid-item--truncated">
                {item.metadata.dataLoader}
              </div>
            )}
          </MarketplaceGridItemMetadata>
        </>
      );
    } else if (purpose === 'ISP_STORAGE') {
      return (
        <>
          <MarketplaceGridItemMetadata label="marketplaceResult.preProcessorType">
            {item.metadata.transformation != null && (
              <div className="marketplace-grid-item--truncated">
                {metadataMap['transformationType'].render(item)}
              </div>
            )}
          </MarketplaceGridItemMetadata>
          <MarketplaceGridItemMetadata label="marketplaceResult.inputColorSpace">
            {item.metadata.transformation?.transformations?.input != null && (
              <div className="marketplace-grid-item--truncated">
                {metadataMap['inputColorSpace'].render(item)}
              </div>
            )}
          </MarketplaceGridItemMetadata>
          <MarketplaceGridItemMetadata label="marketplaceResult.outputColorSpace">
            {item.metadata.transformation?.transformations?.input != null && (
              <div className="marketplace-grid-item--truncated">
                {metadataMap['outputColorSpace'].render(item)}
              </div>
            )}
          </MarketplaceGridItemMetadata>
        </>
      );
    } else if (purpose === 'PP_STORAGE') {
      return (
        <MarketplaceGridItemMetadata label="marketplaceResult.postProcessorType">
          {item.metadata.postProcessorType != null && (
            <div className="marketplace-grid-item--truncated">
              {metadataMap['postProcessorType'].render(item)}
            </div>
          )}
        </MarketplaceGridItemMetadata>
      );
    } else if (purpose === 'MODEL_STORAGE' && item.metadata.compatibleDatasets?.length) {
      const dataset = compatibleDatasetsMap.values().next().value; // It was always random
      const name = dataset?.displayName;
      const count = dataset?.metadata.labelDistribution
        ? getImageCount(dataset.metadata.labelDistribution)
        : null;
      return (
        name != null && (
          <>
            <MarketplaceGridItemMetadata label="marketplaceResult.dataset">
              <div className="marketplace-grid-item--truncated">{name}</div>
            </MarketplaceGridItemMetadata>
            <MarketplaceGridItemMetadata label="marketplaceResult.images">
              <div className="marketplace-grid-item--truncated">{count}</div>
            </MarketplaceGridItemMetadata>
          </>
        )
      );
    } else if (purpose === 'SOLUTION_STORAGE') {
      return (
        <>
          <MarketplaceGridItemMetadata label="marketplaceResult.dataset">
            {item.metadata.dataSet?.name != null && (
              <div className="marketplace-grid-item--truncated">
                {item.metadata.dataSet?.name}
              </div>
            )}
          </MarketplaceGridItemMetadata>
          <MarketplaceGridItemMetadata label="marketplaceResult.model">
            {item.metadata.model?.name != null && (
              <div className="marketplace-grid-item--truncated">
                {item.metadata.model?.name}
              </div>
            )}
          </MarketplaceGridItemMetadata>
        </>
      );
    }
  };

  return (
    <div
      className={cn(
        className,
        'marketplace-grid-item',
        isSelected && 'marketplace-grid-item--selected'
      )}
      data-testid="marketplace-grid-item"
      id="marketplace-grid-item"
      onClick={onDetailsClick}
    >
      {(purpose === 'DATASET_STORAGE' ||
        purpose === 'MODEL_STORAGE' ||
        purpose === 'SOLUTION_STORAGE' ||
        role === 'ORGANIZATION') && (
        <div className="marketplace-grid-item__radio-container">
          <Radio
            color="primary"
            checked={isSelected}
            onChange={onSelect}
            onClick={event => event.stopPropagation()}
            disableRipple
            className="marketplace-grid-item__radio"
          />
        </div>
      )}
      <div className="marketplace-grid-item__banner">
        {purpose === 'DATASET_STORAGE' ||
        (purpose === 'SOLUTION_STORAGE' && item.metadata.dataSet) ? (
          <div
            style={{
              width: THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH,
              height: THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT,
            }}
          >
            <Image
              aspectRatio={THUMBNAIL_GRID_COLUMNS / THUMBNAIL_GRID_ROWS}
              alt={item.displayName}
              color="transparent"
              errorIcon={<MarketplaceItemPlaceholder purpose={purpose} />}
              imageStyle={{
                width: THUMBNAIL_GRID_COLUMNS * THUMBNAIL_WIDTH,
                height: THUMBNAIL_GRID_ROWS * THUMBNAIL_HEIGHT,
              }}
              src={URL.DATASET_THUMBNAILS_FROM_MARKETPLACE({
                datasetName: item.name,
                marketplaceId: item.integrationId,
                rows: THUMBNAIL_GRID_ROWS,
                columns: THUMBNAIL_GRID_COLUMNS,
                width: THUMBNAIL_WIDTH,
                height: THUMBNAIL_HEIGHT,
              })}
            />
          </div>
        ) : (
          <MarketplaceItemPlaceholder purpose={purpose} />
        )}
      </div>
      <div className="marketplace-grid-item__header">
        <h5
          className={cn(
            'marketplace-grid-item__title',
            'marketplace-grid-item--truncated'
          )}
        >
          {item.metadata.displayName}
        </h5>
        <HumanReadableTime
          className={cn('marketplace-grid-item--small', 'marketplace-grid-item--light')}
          date={item.metadata.creationDateTime}
        />
      </div>
      <MarketplaceGridItemRow>
        <MarketplaceGridItemMetadata label="marketplaceResult.purpose">
          {(item.metadata.purpose || item.metadata.model?.modelPurpose) && (
            <div
              className={cn(
                'marketplace-grid-item--small',
                'marketplace-grid-item--uppercase',
                'marketplace-grid-item--truncated'
              )}
            >
              {item.metadata.purpose || item.metadata.model?.modelPurpose}
            </div>
          )}
        </MarketplaceGridItemMetadata>
        <MarketplaceGridItemMetadata label="marketplaceResult.category">
          {item.metadata.categoryName && (
            <div
              className={cn(
                'marketplace-grid-item--small',
                'marketplace-grid-item--truncated'
              )}
            >
              {item.metadata.categoryName}
            </div>
          )}
        </MarketplaceGridItemMetadata>
      </MarketplaceGridItemRow>
      <MarketplaceGridItemRow>
        <MarketplaceGridItemMetadata>
          {item.metadata.tags && (
            <div className="marketplace-grid-item__tags">
              <MarketplaceTagList
                tags={item.metadata.tags}
                limit={TAG_LIMIT}
                size="small"
              />
            </div>
          )}
        </MarketplaceGridItemMetadata>
      </MarketplaceGridItemRow>
      <MarketplaceGridItemRow className="marketplace-grid-item__resource-info">
        {renderResourceDetails(purpose)}
      </MarketplaceGridItemRow>
      <MarketplaceGridItemRow>
        <MarketplaceGridItemMetadata>
          {item.metadata.description && (
            <div
              className={cn(
                'marketplace-grid-item--small',
                'marketplace-grid-item--light'
              )}
            >
              {item.metadata.description.length > DESC_CHAR_LIMIT
                ? `${item.metadata.description.substring(0, DESC_CHAR_LIMIT)}...`
                : item.metadata.description}
            </div>
          )}
        </MarketplaceGridItemMetadata>
      </MarketplaceGridItemRow>
      <div className="marketplace-grid-item__toolbar">
        <IconButton
          aria-label="view details"
          className="marketplace-grid-item__view-details"
          onClick={handleDetailsClick}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
    </div>
  );
};
