import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, ButtonProps} from '@material-ui/core';
import ArrowLeftIcon from './../../../assets/icons/ArrowLeftIcon';

type AllLabelsButtonProps = {
  visible: boolean;

  onClick: ButtonProps['onClick'];
};

export function AllLabelsButton(props: AllLabelsButtonProps) {
  return props.visible ? (
    <Button className="data-viewer2__all-labels" onClick={props.onClick}>
      <ArrowLeftIcon /> <FormattedMessage id="dataViewer.allLabels" />
    </Button>
  ) : null;
}
