import {IconButton, InputAdornment} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {Cancel} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import {AxiosError} from 'axios';
import cn from 'classnames';
import {
  ErrorMessage as FormikErrorMessage,
  FieldMetaProps,
  Form,
  useField,
  useFormikContext,
} from 'formik';
import {isError} from 'lodash';
import React, {forwardRef, ReactNode, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import FormButton, {FormButtonProps} from '../../base-components/StudioButton/FormButton';
import AlertFIlledIcon from '../../assets/icons/AlertFIlledIcon';
import {Logo} from '../../base-components/StudioLogo';
import {
  StudioPasswordField,
  StudioPasswordFieldProps,
} from '../../base-components/StudioPasswordField';
import {
  StudioTextField,
  StudioTextFieldProps,
} from '../../base-components/StudioTextField';
import {StudioTooltip} from '../../base-components/StudioTooltip';
import {ErrorMessage as StudioErrorMessage} from '../../types/response/ErrorMessage';
import {SpringBootErrorAttributes} from '../../types/response/SpringBootErrorAttributes';
import {UnparsedJson} from '../../types/utility/UnparsedJson';
import {PasswordRequirements} from './PasswordRequirements';
import './AuthPage.scss';
import {Optional} from '../../types/utility/Optional';

export type AuthPageContainerProps = {
  children: ReactNode;
};

export const AuthPageContainer = ({children}: AuthPageContainerProps) => (
  <div className="auth-page__container">
    <h1 className="auth-page__banner">
      <Logo />
    </h1>
    <div className="auth-page__dialog">{children}</div>
  </div>
);

export type AuthPageFormProps = {
  children: ReactNode;
};

export const AuthPageForm = ({children}: AuthPageFormProps) => (
  <Form className="auth-page__form">{children}</Form>
);

export type AuthPageHeaderProps = {
  children: ReactNode;
  className?: string;
};

export const AuthPageHeader = ({children, className}: AuthPageHeaderProps) => (
  <h2 className={cn('auth-page__header', className)}>{children}</h2>
);

type ErrorEntry = StudioErrorMessage | UnparsedJson;

export type AuthErrorProps = {
  error: ErrorEntry[] | AxiosError<SpringBootErrorAttributes> | Error | null;
};

export const AuthError = ({error}: AuthErrorProps) => {
  const isErrorMessage = (obj: ErrorEntry): obj is StudioErrorMessage => {
    return obj.hasOwnProperty('message');
  };

  const getMessageFromObject = (obj: StudioErrorMessage) => {
    const firstKey = Object.keys(obj)?.[0];
    return isErrorMessage(obj) ? obj.message : obj?.[firstKey] || null;
  };

  const getMessageFromString = (s: UnparsedJson) => {
    try {
      const obj = JSON.parse(s);
      return getMessageFromObject(obj);
    } catch (err) {
      console.error(err);
      return s;
    }
  };

  const getMessage = (t: ErrorEntry) => {
    return isErrorMessage(t) ? getMessageFromObject(t) : getMessageFromString(t);
  };

  const isAxiosError = (
    error: AuthErrorProps['error']
  ): error is AxiosError<SpringBootErrorAttributes> => {
    return error?.hasOwnProperty('response') ?? false;
  };

  const renderErrorMessages = () => {
    if (isAxiosError(error)) {
      return error?.response?.data?.message;
    } else if (isError(error)) {
      return error.message;
    } else {
      return error?.map((obj, i) => <div key={i}>{getMessage(obj)}</div>);
    }
  };

  return (
    <Collapse in={Array.isArray(error) && error.length > 0} className="auth-page__error">
      <Alert
        variant="filled"
        severity="error"
        icon={<AlertFIlledIcon fontSize="inherit" />}
      >
        <h6>
          <FormattedMessage id="auth.error" />
        </h6>
        {renderErrorMessages()}
      </Alert>
    </Collapse>
  );
};

export type AuthPageBodyProps = {
  children: ReactNode;
  className?: string;
};

export const AuthPageBody = forwardRef<HTMLDivElement, AuthPageBodyProps>(
  ({children, className}, ref) => (
    <div ref={ref} className={cn('auth-page__body', className)}>
      {children}
    </div>
  )
);

export type AuthPageSummaryProps = {
  children: ReactNode;
  className?: string;
};

export const AuthPageSummary = ({children, className}: AuthPageSummaryProps) => (
  <div className={cn('auth-page__summary', className)}>{children}</div>
);

const hasError = <T,>(meta: FieldMetaProps<T>) => {
  return Boolean(meta.touched && meta.error);
};

export type AuthPageEmailFieldProps = StudioTextFieldProps & {name: string};

export const AuthPageEmailField = ({
  name,
  className,
  InputProps,
  InputLabelProps,
  ...rest
}: AuthPageEmailFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const intl = useIntl();

  return (
    <StudioTextField
      label={intl.formatMessage({id: 'auth.emailAddress'})}
      placeholder={intl.formatMessage({id: 'auth.emailAddressPlaceholder'})}
      error={hasError(meta)}
      helperText={<FormikErrorMessage name={name} />}
      className={cn('auth-page__form-control', className)}
      data-testid={name}
      id={name}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="button" onClick={() => helpers.setValue('')} tabIndex={-1}>
              <Cancel />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
        ...field,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        required: true,
      }}
      {...rest}
    />
  );
};

export type AuthPagePasswordFieldProps = StudioPasswordFieldProps & {name: string};

export const AuthPagePasswordField = forwardRef<
  HTMLDivElement,
  AuthPagePasswordFieldProps
>(({name, className, InputProps, ...rest}, ref) => {
  const [field, meta] = useField(name);
  const intl = useIntl();

  return (
    <StudioPasswordField
      ref={ref}
      label={intl.formatMessage({id: 'auth.password'})}
      placeholder={intl.formatMessage({id: 'auth.passwordPlaceholder'})}
      error={hasError(meta)}
      helperText={<FormikErrorMessage name={name} />}
      className={cn('auth-page__form-control', className)}
      data-testid={name}
      id={name}
      InputProps={{...field, ...InputProps}}
      {...rest}
    />
  );
});

type Criteria = {
  message: string;
  valid: boolean;
};

export type AuthPagePasswordCriteriaFieldProps = StudioTextFieldProps & {
  name: string;
  criteria: Criteria[];
};

export const AuthPagePasswordCriteriaField = forwardRef<
  HTMLDivElement,
  AuthPagePasswordCriteriaFieldProps
>(({name, inputProps, criteria, ...rest}, ref) => {
  const [focused, setFocused] = useState(false);
  const [, meta] = useField(name);

  return (
    <StudioTooltip
      open={Boolean(focused || (meta.touched && meta.error))}
      title={
        <div className="reset-password__tooltip" data-testid="new-password-tooltip">
          {criteria.map(({message, valid}, i) => (
            <div
              key={i}
              className={cn('reset-password__tooltip-text', {
                'reset-password__tooltip-text--fade': valid,
              })}
            >
              {message}
            </div>
          ))}
        </div>
      }
    >
      <AuthPagePasswordField
        ref={ref}
        name={name}
        helperText=""
        inputProps={{
          onBlur() {
            setFocused(false);
          },
          onFocus() {
            setFocused(true);
          },
          ...inputProps,
        }}
        {...rest}
      />
    </StudioTooltip>
  );
});

export type AuthPageNewPasswordFieldProps = Optional<
  AuthPagePasswordCriteriaFieldProps,
  'criteria'
>;

export const AuthPageNewPasswordField = forwardRef<
  HTMLDivElement,
  AuthPageNewPasswordFieldProps
>(({name, InputLabelProps, criteria, ...rest}, ref) => {
  const [field] = useField(name);

  return (
    <AuthPagePasswordCriteriaField
      criteria={PasswordRequirements.map(({validator, message}) => ({
        message,
        valid: validator.isValidSync(field.value),
      }))}
      ref={ref}
      name={name}
      InputLabelProps={{
        ...InputLabelProps,
        required: true,
      }}
      {...rest}
    />
  );
});

export type AuthPageFooterProps = {
  children: ReactNode;
  className?: string;
};

export const AuthPageFooter = ({children, className}: AuthPageFooterProps) => (
  <div className={cn('auth-page__footer', className)}>{children}</div>
);

export type AuthPageSecondaryButtonProps = Optional<
  FormButtonProps,
  'type' | 'buttonRole'
>;

export const AuthPageSecondaryButton = ({
  className,
  ...rest
}: AuthPageSecondaryButtonProps) => (
  <FormButton
    type="button"
    buttonRole="naked"
    customClass={cn('auth-page__secondary-button', className)}
    {...rest}
  />
);

export type AuthPageSubmitButtonProps = Optional<FormButtonProps, 'type' | 'buttonRole'>;

export const AuthPageSubmitButton = ({className, ...rest}: AuthPageSubmitButtonProps) => {
  const {isSubmitting, isValid} = useFormikContext();

  return (
    <FormButton
      type="submit"
      buttonRole="primary"
      customClass={cn('auth-page__submit-button', className)}
      disabled={!isValid || isSubmitting}
      {...rest}
    />
  );
};
