import React from 'react';
import {Pagination} from '@material-ui/lab';

type PageNumbersProps = {
  page: number;
  pageCount: number;
  onChange(page: number): void;
};

export function PageNumbers(props: PageNumbersProps) {
  return (
    <div className="data-viewer2__pagination-container">
      <Pagination
        shape="rounded"
        page={props.page}
        count={props.pageCount}
        className="data-viewer2__pagination"
        onChange={(_, page) => props.onChange(page)}
      />
    </div>
  );
}
