import React, {useEffect, useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router';
import {Pagination} from '@material-ui/lab';
import {useSelector} from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import {RootState, useAppDispatch} from '../../../store';
import {
  deleteDeployment,
  deleteInferenceApp,
  fetchInferenceApps,
} from '../../../store/prodDeployments/dashboard';
import {
  InferenceApplicationsRequest,
  InferenceAppSortableColumn,
} from '../../../types/deployment/InferenceApplicationsRequest';
import {StudioKebabMenu} from '../../../base-components/StudioKebabMenu';
import {toast} from '../../../base-components/StudioToast';
import {
  InferenceApplicationInfo,
  InferenceDeploymentInfo,
} from '../../../types/deployment/InferenceApplicationsResponse';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {ContentCopyIcon} from '../../ContentCopyIcon/ContentCopyIcon';
import {RuntimeType} from '../../../types/model/framework/RuntimeType';
import {
  getInferenceApplications,
  getInferenceApplicationsCount,
} from './../../../store/prodDeployments/selectors';
import {isNumber} from 'lodash';

const PAGE_SIZE = 7;
const UPDATE_INTERVAL = 5000;

export const InferenceAppsTable = ({search}: InferenceAppsTableProps) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [sort, setSort] = useState<InferenceAppSortableColumn>('name');
  const [sortDir, setSortDir] = useState<-1 | 1>(1);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
    item: InferenceApplicationRow | null;
  }>({isOpen: false, item: null});
  const [page, setPage] = useState(0);
  const userId = useSelector((state: RootState) => state.user.userId);
  const groupId = useSelector((state: RootState) => state.user.groupId);

  useEffect(() => {
    if (groupId) {
      const requestBody: InferenceApplicationsRequest = {
        filterOutNonInferenceApps: true,
        firstPage: page,
        pageSize: PAGE_SIZE,
        paginated: true,
        orderDefinitions: [{field: sort, direction: sortDir === -1 ? 'DESC' : 'ASC'}],
        params: {
          name: search,
        },
        groupId,
      };

      let promise = dispatch(fetchInferenceApps(requestBody));
      const interval = setInterval(() => {
        promise = dispatch(fetchInferenceApps(requestBody));
      }, UPDATE_INTERVAL);

      return () => {
        promise.abort();
        clearInterval(interval);
      };
    }
  }, [search, sort, sortDir, page, groupId, dispatch]);

  const inferenceApps = useSelector(getInferenceApplications);
  const inferenceAppsCount = useSelector(getInferenceApplicationsCount);

  const paginationCount =
    Math.ceil(inferenceAppsCount / PAGE_SIZE) === 0
      ? 1
      : Math.ceil(inferenceAppsCount / PAGE_SIZE);
  const paginationPage = page + 1;

  const inferenceType = {
    Test: 'Test',
    Production: 'Production',
  };

  type InferenceType = keyof typeof inferenceType;

  type InferenceApplicationRow = {
    id: string;
    ownerId: string;
    applicationId: string;
    deploymentId: string | undefined;
    projectId: string;
    applicationName: string;
    deploymentName: string | undefined;
    deviceName: string | undefined;
    boardName: string | undefined;
    projectName: string;
    type: InferenceType;
    datasetName: string;
    modelName: string;
    batchInferenceUrl: string;
    inferencePageUrl: string;
    runtimeType: RuntimeType;
  };

  const expandedApps: Array<InferenceApplicationRow> = new Array<
    InferenceApplicationRow
  >();
  let totalResults = 0;

  inferenceApps.forEach((app: InferenceApplicationInfo) => {
    let appRow: InferenceApplicationRow = {
      id: totalResults.toString(),
      ownerId: app.ownerId || '',
      applicationId: app.id,
      deploymentId: undefined,
      projectId: app.projectId,
      applicationName: app.name,
      deviceName: undefined,
      boardName: undefined,
      deploymentName: undefined,
      projectName: app.projectName,
      type: 'Test',
      datasetName: app.datasetName,
      modelName: app.modelName,
      batchInferenceUrl: app.batchInferenceUrl || '',
      inferencePageUrl: app.inferencePageUrl || '',
      runtimeType: app.runtimeType || 'ONNX_RUNTIME',
    };

    expandedApps.push(appRow);

    totalResults += 1;

    if (app.deployments) {
      const sortedDeployments = [...app.deployments];

      sortedDeployments.sort((d1, d2) => {
        if (d1.name > d2.name) {
          return +1;
        } else if (d1.name < d2.name) {
          return -1;
        } else {
          return 0;
        }
      });

      sortedDeployments.forEach((deployment: InferenceDeploymentInfo) => {
        let prodRow = Object.assign({}, appRow);

        prodRow.id = totalResults.toString();
        prodRow.deploymentId = deployment.id;
        prodRow.deploymentName = deployment.name;
        prodRow.batchInferenceUrl = deployment.batchInferenceUrl;
        prodRow.inferencePageUrl = deployment.inferencePageUrl;
        prodRow.deviceName = deployment.targetDeviceName;
        prodRow.boardName = deployment.targetBoardName;
        prodRow.type = 'Production';
        expandedApps.push(prodRow);
        totalResults += 1;
      });
    }
  });

  const handleSort = (column: InferenceAppSortableColumn) => {
    if (column === sort) {
      setSortDir(sortDir === 1 ? -1 : 1);
    } else {
      setSort(column);
      setSortDir(1);
    }
  };

  const getSortDirection = (): 'asc' | 'desc' => {
    return sortDir === 1 ? 'asc' : 'desc';
  };

  const buildURL = (path: string) => {
    const {hostname, protocol, port} = window.location;
    if (port) {
      return `${protocol}//${hostname}:${port}${path}`;
    }
    return `${protocol}//${hostname}${path}`;
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className="prod-deploy-dashboard__deployments"
        elevation={0}
      >
        <Table stickyHeader className="prod-deploy-dashboard__table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sort === 'name'}
                  direction={getSortDirection()}
                  onClick={() => handleSort('name')}
                  IconComponent={ArrowDropDown}
                >
                  <FormattedMessage id="prodDeployment.application" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.deployment" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.node" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.board" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.project" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.type" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.dataset" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.model" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="prodDeployment.api" />
              </TableCell>
              <TableCell />
              <TableCell>
                <FormattedMessage id="prodDeployment.singlePage" />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {expandedApps.length ? (
              expandedApps.map(row => {
                return (
                  <TableRow key={row.id} className="prod-deploy-dashboard__row">
                    <TableCell>{row.applicationName}</TableCell>
                    <TableCell>{row.deploymentName || row.applicationName}</TableCell>
                    <TableCell>{row.deviceName}</TableCell>
                    <TableCell>{row.boardName}</TableCell>
                    <TableCell>{row.projectName}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.datasetName}</TableCell>
                    <TableCell>{row.modelName}</TableCell>
                    <TableCell>
                      {row.batchInferenceUrl && (
                        <a href={row.batchInferenceUrl}>
                          {buildURL(row.batchInferenceUrl)}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.batchInferenceUrl && (
                        <CopyToClipboard
                          text={buildURL(row.batchInferenceUrl)}
                          onCopy={() => {
                            toast.success('Copied to clipboard!');
                          }}
                        >
                          <span>
                            <ContentCopyIcon />
                          </span>
                        </CopyToClipboard>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.inferencePageUrl && <a href={row.inferencePageUrl}>open</a>}
                    </TableCell>
                    <TableCell>
                      {row.inferencePageUrl && (
                        <CopyToClipboard
                          text={buildURL(row.inferencePageUrl)}
                          onCopy={() => {
                            toast.success('Copied to clipboard!');
                          }}
                        >
                          <span>
                            <ContentCopyIcon />
                          </span>
                        </CopyToClipboard>
                      )}
                    </TableCell>
                    <TableCell>
                      <StudioKebabMenu
                        items={[
                          {
                            title: intl.formatMessage({
                              id: 'prodDeployment.viewApiSPA',
                            }),
                            onClick: () => {
                              if (row.deploymentId) {
                                history.push(
                                  `/project/${row.projectId}/inference/${row.applicationId}/deployment/${row.deploymentId}/configure`
                                );
                              } else {
                                history.push(
                                  `/project/${row.projectId}/inference/${row.applicationId}/create`
                                );
                              }
                            },
                          },
                          {
                            title: intl.formatMessage({
                              id: 'prodDeployment.deployToProduction',
                            }),
                            onClick: () => {
                              history.push(
                                `/project/${row.projectId}/inference/${row.applicationId}/deployments/new`
                              );
                            },
                            disabled:
                              row.ownerId !== userId ||
                              row.type === 'Production' ||
                              row.runtimeType === 'ONNX_RUNTIME_WEB',
                          },
                          {
                            title: intl.formatMessage({
                              id: 'prodDeployment.viewDetails',
                            }),
                            onClick: () => {
                              if (row.type === 'Test') {
                                history.push(
                                  `/project/${row.projectId}/inference/${row.applicationId}/details`
                                );
                              } else if (row.type === 'Production') {
                                history.push(
                                  `/project/${row.projectId}/inference/${row.applicationId}/deployment/${row.deploymentId}/details`
                                );
                              }
                            },
                          },
                          {
                            title: intl.formatMessage({
                              id: 'form.delete',
                            }),
                            onClick: async () => {
                              setDeleteConfirmation({isOpen: true, item: row});
                            },
                            disabled: row.ownerId !== userId,
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={100} className="prod-deploy-dashboard__no-results">
                  <FormattedMessage id="dashboard.noResults" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isNumber(inferenceAppsCount) && (
        <Pagination
          className="prod-deploy-dashboard__pagination"
          count={paginationCount}
          page={paginationPage}
          shape="rounded"
          onChange={(_, page) => setPage(page - 1)}
          data-testid="prod-deploy-dashboard-pagination"
          id="prod-deploy-dashboard-pagination"
        />
      )}

      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'prodDeployment.deleteConfirmTitle'})}
        message={intl.formatMessage({id: 'prodDeployment.deleteConfirmMessage'})}
        submitLabel={intl.formatMessage({id: 'prodDeployment.deleteConfirmSubmitLabel'})}
        open={
          deleteConfirmation.isOpen &&
          (deleteConfirmation.item?.deploymentId?.length || 0) > 0
        }
        onClose={() => setDeleteConfirmation({isOpen: false, item: null})}
        onOk={async () => {
          const deploymentId = deleteConfirmation.item?.deploymentId;

          if (deploymentId) {
            await dispatch(deleteDeployment(deploymentId));
            toast.success(`Successfully deleted application`);
          }
          setDeleteConfirmation({isOpen: false, item: null});
        }}
      />
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'prodDeployment.deleteAppConfirmTitle'})}
        message={intl.formatMessage({id: 'prodDeployment.deleteAppConfirmMessage'})}
        submitLabel={intl.formatMessage({id: 'prodDeployment.deleteConfirmSubmitLabel'})}
        open={
          deleteConfirmation.isOpen && deleteConfirmation.item?.deploymentId === undefined
        }
        onClose={() => setDeleteConfirmation({isOpen: false, item: null})}
        onOk={async () => {
          if (deleteConfirmation.item) {
            await dispatch(
              deleteInferenceApp({
                projectId: deleteConfirmation.item.projectId,
                id: deleteConfirmation.item.applicationId,
              })
            );
            toast.success(`Successfully deleted application`);
          }

          setDeleteConfirmation({isOpen: false, item: null});
        }}
      />
    </>
  );
};

type InferenceAppsTableProps = {
  search: string;
};
