import React from 'react';
import {Search} from '@material-ui/icons';
import {FormattedMessage} from 'react-intl';

type LabelSelectedProps = {
  label: string;
  visible: boolean;
};

export function LabelSelected(props: LabelSelectedProps) {
  return props.visible ? (
    <div className="data-viewer2__label">
      <Search className="data-viewer2__search-icon" />{' '}
      <span className="data-viewer2__current-label">
        <FormattedMessage id="dataViewer.filesLabeled" /> {props.label}
      </span>
    </div>
  ) : null;
}
