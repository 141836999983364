import React, {useEffect, useState} from 'react';

import {
  AnnotationDataType,
  AnnotationType,
  CategoryType,
} from '../../../types/dataset/DatasetGetAnnotationsResponse';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../../store';
import {dataPrepareActions, mergeAnnotations} from '../../../store/dataPrepare';
import {ConfirmDialog} from '../../../base-components/StudioConfirmDialog';
import {useIntl} from 'react-intl';
import {differenceBy, forEach, uniq} from 'lodash';
import {DetectionPrepare} from './DetectionPrepare';
import {PageListProvider} from './PageListProvider';
import {DataPreparationOrigin} from '../../../types/dataset/DataPreparationContainer';
import {BoxLabelProps} from '../../../base-components/StudioAnnotator/types';
import {MergeDialog} from '../MergeDialog';

const {setAnnotationData} = dataPrepareActions;

type AnnotationDictionary = {
  [imageId: number]: BoxLabelProps[];
};

type DetectionViewProps = {
  projectId: string;
  datasetId?: string;
  inProgress: boolean;
  origin: DataPreparationOrigin;
  onSubmit: (annotations: AnnotationDataType) => void;
  onBack: () => void;
};

export const DetectionView = ({
  projectId,
  datasetId,
  inProgress,
  origin,
  onSubmit,
  onBack,
}: DetectionViewProps) => {
  const intl = useIntl();
  const [isDetectionSubmitConfirmOpen, setIsDetectionSubmitConfirmOpen] = useState(false);
  const [isDetectionSubmitMergeOpen, setIsDetectionSubmitMergeOpen] = useState(false);
  const [processedAnnotations, setProcessedAnnotations] = useState<{
    merged: AnnotationType[];
    discarded: AnnotationType[];
    final?: AnnotationType[];
  }>({merged: [], discarded: [], final: []});
  const [activeChanges, setActiveChanges] = useState<AnnotationDictionary>({});
  const [selectedImageId, setSelectedImageId] = useState<number | undefined>();

  const dispatch = useAppDispatch();
  const annotationData = useSelector(
    (state: RootState) => state.dataPrepare.annotationData
  );
  const detectionAnnotations: AnnotationType[] = annotationData?.annotations || [];
  const currentCategories: CategoryType[] = annotationData?.categories || [];
  const currentCategoriesMap = currentCategories.reduce<{
    [key: string]: CategoryType;
  }>((acc, curr) => ({...acc, [curr.name]: curr}), {});
  const currentImages = annotationData?.images || [];

  // Send changes to Backend
  const mergeActiveChanges = async (imageId: number, value: BoxLabelProps[]) => {
    if (datasetId && annotationData && imageId !== null) {
      const annotations: AnnotationType[] = [];

      const plain = value
        ?.filter(item => !!item.label)
        .map(annotation => {
          return {
            id: (annotation.id as unknown) as number,
            image_id: Number(imageId),
            category_id:
              annotation.label && currentCategoriesMap[annotation.label]
                ? Number(currentCategoriesMap[annotation.label].id)
                : undefined,
            bbox: [annotation.x, annotation.y, annotation.width, annotation.height],
          };
        });

      value
        ?.filter(item => !!item.label)
        .forEach(annotation =>
          annotations.push({
            id: Number(annotation.id),
            image_id: Number(imageId),
            category_id:
              annotation.label && currentCategoriesMap[annotation.label]
                ? Number(currentCategoriesMap[annotation.label].id)
                : undefined,
            bbox: [annotation.x, annotation.y, annotation.width, annotation.height],
          })
        );

      const mergeToLocal = () => {
        const diffArr = differenceBy(annotationData.annotations, plain, 'id');
        dispatch(
          setAnnotationData({
            ...annotationData,
            annotations: diffArr.concat(plain),
          })
        );
      };

      mergeToLocal();

      await dispatch(
        mergeAnnotations({
          resourceId: datasetId,
          images: [{id: imageId}],
          annotations,
        })
      );
    }
  };

  const onSelectionChange = (imageId: number) => setSelectedImageId(imageId);

  const handleDetectionChange = (imageId: number, rawAnnotations?: BoxLabelProps[]) => {
    if (datasetId && annotationData && rawAnnotations !== undefined) {
      setActiveChanges({...activeChanges, [imageId]: rawAnnotations});
    }
  };

  const handleDetectionSubmit = (final: AnnotationType[]) => {
    if (
      annotationData &&
      final?.length &&
      currentCategories.length &&
      currentImages.length
    ) {
      const validAnnotations = final.filter(el => el?.category_id !== undefined);

      const validImageId = uniq(validAnnotations?.map(el => el.image_id));
      const validCategoryId = uniq(validAnnotations?.map(el => el.category_id));
      const validImages = currentImages.filter(el => validImageId.includes(el.id));
      const validCategories = currentCategories.filter(el =>
        validCategoryId.includes(el.id)
      );

      saveActiveChanges();
      onSubmit({
        ...annotationData,
        images: validImages,
        categories: validCategories,
        annotations: validAnnotations,
      });
    }
  };

  const onDone = (merged: AnnotationType[], discarded: AnnotationType[]) => {
    // Validate Annotations
    if (merged.length && merged.length !== discarded.length) {
      setProcessedAnnotations({merged, discarded});
      setIsDetectionSubmitMergeOpen(true);
    } else {
      validateLabelAndCategories(merged);
    }
  };

  const onMerge = () => {
    setIsDetectionSubmitMergeOpen(false);
    validateLabelAndCategories(processedAnnotations.merged);
  };

  const onDiscard = () => {
    setIsDetectionSubmitMergeOpen(false);
    validateLabelAndCategories(processedAnnotations.discarded);
  };

  const validateLabelAndCategories = (annotations: AnnotationType[]) => {
    setProcessedAnnotations({...processedAnnotations, final: annotations});
    const hasOrphanAnnotations = () => !annotations?.every(el => !!el.category_id);

    const hasOrphanImages = () => {
      const imagesUsedList = uniq(annotations.map(el => el.image_id));
      return imagesUsedList.length !== currentImages.length;
    };

    if (hasOrphanAnnotations() || hasOrphanImages()) {
      setIsDetectionSubmitConfirmOpen(true);
    } else {
      handleDetectionSubmit(annotations);
    }
  };

  const saveActiveChanges = () => {
    // Save unmerged changes
    const keys = Object.keys(activeChanges);
    const updatedDict = {...activeChanges};

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key !== selectedImageId?.toString()) {
        const keyNum = Number(key);
        const val = activeChanges[keyNum];

        mergeActiveChanges(keyNum, val);
        delete updatedDict[keyNum];
        setActiveChanges({...updatedDict});
      }
    }
  };

  useEffect(
    () => saveActiveChanges(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedImageId]
  );

  const isValid = Boolean(annotationData?.images?.length);
  const disableForward = !isValid || inProgress;

  const BackFromDetectionView = () => {
    saveActiveChanges();
    onBack();
  };

  return (
    <PageListProvider>
      <DetectionPrepare
        projectId={projectId}
        datasetId={datasetId}
        annotations={detectionAnnotations}
        labels={currentCategories}
        labelsMap={currentCategoriesMap}
        images={currentImages}
        disableForward={disableForward}
        disableBack={inProgress}
        origin={origin}
        onForward={onDone}
        onSelectionChange={onSelectionChange}
        onChange={handleDetectionChange}
        onBack={BackFromDetectionView}
      />
      <ConfirmDialog
        type="confirm"
        title={intl.formatMessage({id: 'dataPrep.detection.title'})}
        message={intl.formatMessage({id: 'dataPrep.finalize.warning.desc.detection'})}
        open={isDetectionSubmitConfirmOpen}
        onClose={() => setIsDetectionSubmitConfirmOpen(false)}
        onOk={() => {
          setIsDetectionSubmitConfirmOpen(false);
          handleDetectionSubmit(processedAnnotations.final || []);
        }}
        submitLabel={intl.formatMessage({id: 'form.confirm'})}
      />
      <MergeDialog
        open={isDetectionSubmitMergeOpen}
        title={intl.formatMessage({id: 'dataPrep.detection.title'})}
        onClose={() => setIsDetectionSubmitMergeOpen(false)}
        onMerge={onMerge}
        onDiscard={onDiscard}
      />
    </PageListProvider>
  );
};
