import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ViewExpandIcon extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <svg
          width="16px"
          height="15px"
          viewBox="0 0 16 15"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M17.8203125,18.3203125 L17.8203125,12.5 L19.5,12.5 L19.5,18.3203125 C19.5,18.7890648 19.3372412,19.1861963 19.0117188,19.5117188 C18.6861963,19.8372412 18.2890648,20 17.8203125,20 L6.1796875,20 C5.71093516,20 5.31380371,19.8372412 4.98828125,19.5117188 C4.66275879,19.1861963 4.5,18.7890648 4.5,18.3203125 L4.5,6.6796875 C4.5,6.21093516 4.66275879,5.81380371 4.98828125,5.48828125 C5.31380371,5.16275879 5.71093516,5 6.1796875,5 L12,5 L12,6.6796875 L6.1796875,6.6796875 L6.1796875,18.3203125 L17.8203125,18.3203125 Z M13.6796875,5 L19.5,5 L19.5,10.8203125 L17.8203125,10.8203125 L17.8203125,7.8515625 L9.65625,16.015625 L8.484375,14.84375 L16.6484375,6.6796875 L13.6796875,6.6796875 L13.6796875,5 Z"
              id="viewExpandIcon"
            ></path>
          </defs>
          <g
            id="Style-Guide"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Blaize-Studio---Icons"
              transform="translate(-848.000000, -5479.000000)"
            >
              <g id="General-Icons" transform="translate(56.000000, 753.000000)">
                <g id="row" transform="translate(0.000000, 4683.000000)">
                  <g id="Icon" transform="translate(641.000000, 0.000000)">
                    <g
                      id="general-/-view-expand-/-Default"
                      transform="translate(147.000000, 38.000000)"
                    >
                      <rect id="Container" x="0" y="0" width="24" height="24"></rect>
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#viewExpandIcon"></use>
                      </mask>
                      <use
                        id="expand"
                        fill="currentColor"
                        xlinkHref="#viewExpandIcon"
                      ></use>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

ViewExpandIcon.propTypes = {
  className: PropTypes.string,
};

export default ViewExpandIcon;
