import React, {useEffect, useState} from 'react';
import axios from 'axios';
import URL from '../../config/url';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import {UserDetailsResponse} from '../../types/user/UserDetailsResponse';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from '../../base-components/StudioToast';
import {useStudioMode} from '../StudioMode/StudioModeProvider';
import './UserDetails.scss';
import {ContentCopyIcon} from '../ContentCopyIcon/ContentCopyIcon';
import {DownloadEdgeInstallerDialog} from './DownloadEdgeInstallerDialog';
import DownloadIcon from '@material-ui/icons/GetApp';

export const fetchUserToken = async () => {
  try {
    const {data} = await axios.get<{body: string; errors: any[]}>(
      URL.RETRIEVE_USER_TOKEN,
      {
        headers: {bypassDefaultErrorHandler: true},
      }
    );
    if (data.errors?.length) {
      const {data} = await axios.post<{body: string; errors: any[]}>(
        URL.CREATE_USER_TOKEN,
        {
          headers: {bypassDefaultErrorHandler: true},
        }
      );
      return data.body;
    }
    return data.body;
  } catch (e) {
    console.error(e);
  }
};

export const UserDetails = () => {
  const intl = useIntl();
  const groupId = useSelector((state: RootState) => state.user.groupId);
  const gspMonitorUrlConfig = useSelector(
    (state: RootState) => state.config.gspMonitorUrl
  );

  // based on the backend configuration this will never be anything but the gsp monitor url.
  const gspMonitorUrl =
    gspMonitorUrlConfig != null
      ? gspMonitorUrlConfig
      : intl.formatMessage({id: 'about.gspMonitorUrl.not.configured'});

  const [email, setEmail] = useState('');
  const [orgToken, setOrgToken] = useState('');
  const [showEdgeDownloadModal, setShowEdgeDownloadModal] = useState(false);

  const {mode} = useStudioMode();

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const {data} = await axios.get<UserDetailsResponse>(URL.USER_DETAILS);
        const email = data.body.email;
        setEmail(email);
      } catch (e) {}
    };
    const fetchToken = async () => {
      const token = await fetchUserToken();
      if (token) {
        setOrgToken(token);
      }
    };

    if (mode === 'FULL') {
      fetchToken();
    }
    fetchEmail();
  }, [mode]);

  return (
    groupId && (
      <>
        <div className="user-details-info">
          <Typography variant="subtitle1">
            <FormattedMessage id="about.user.email" values={{email: email}} />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage id="about.user.org.name" values={{name: groupId}} />
            <CopyToClipboard
              text={groupId}
              onCopy={() => {
                toast.success(intl.formatMessage({id: 'about.copied.to.clipboard'}));
              }}
            >
              <span className="user-details-button">
                <ContentCopyIcon />
              </span>
            </CopyToClipboard>
          </Typography>
          {mode === 'FULL' && (
            <>
              <Typography variant="subtitle1">
                <FormattedMessage id="about.user.org.token" values={{token: orgToken}} />
                <CopyToClipboard
                  text={orgToken}
                  onCopy={() => {
                    toast.success(intl.formatMessage({id: 'about.copied.to.clipboard'}));
                  }}
                >
                  <span className="user-details-button">
                    <ContentCopyIcon />
                  </span>
                </CopyToClipboard>
              </Typography>
              <Typography variant="subtitle1">
                <FormattedMessage
                  id="about.device.registration.url"
                  values={{url: gspMonitorUrl}}
                />
                <CopyToClipboard
                  text={gspMonitorUrl}
                  onCopy={() => {
                    toast.success(intl.formatMessage({id: 'about.copied.to.clipboard'}));
                  }}
                >
                  <span className="user-details-button">
                    <ContentCopyIcon />
                  </span>
                </CopyToClipboard>
              </Typography>
              <Button
                className="edge-installer-download"
                variant="contained"
                onClick={() => setShowEdgeDownloadModal(true)}
              >
                <DownloadIcon />
                <span className="horizontal-spacer"></span>
                <FormattedMessage id="download.edge.installer.title" />
              </Button>
              <div className="vertical-spacer"></div>
            </>
          )}
          <DownloadEdgeInstallerDialog
            isOpen={showEdgeDownloadModal}
            onClose={() => setShowEdgeDownloadModal(false)}
          />
        </div>
      </>
    )
  );
};
