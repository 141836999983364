import useSWRImmutable from 'swr/immutable';

import {request} from '../request';

export type LoginSettingsResponse = {};

export const loginSettingsUtl = '/user/loginSettings';

export async function getLoginSettings() {
  const response = await request<LoginSettingsResponse>({
    method: 'get',
    url: loginSettingsUtl,
  });

  return response.body;
}

export function useLoginSettings() {
  return useSWRImmutable(loginSettingsUtl, getLoginSettings, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
}
