import {chain, fromPairs, orderBy, range, uniq} from 'lodash';

import {DatasetListRequest} from './../../../types/dataset/DatasetListRequest';
import {DatasetListResponse} from './../../../types/dataset/DatasetListResponse';
import {DatasetLabelsRequest} from './../../../types/dataset/DatasetLabelsRequest';
import {DatasetLabelsResponse} from './../../../types/dataset/DatasetLabelsResponse';
import {
  DatasetDistribution,
  DatasetDistributionResponse,
} from './../../../types/dataset/DatasetDistributionResponse';
import {
  ImageType,
  AnnotationType,
  DatasetGetAnnotationsResponse,
} from './../../../types/dataset/DatasetGetAnnotationsResponse';

export function getLabelData(
  annotationData: DatasetGetAnnotationsResponse | undefined,
  query: DatasetLabelsRequest
) {
  const labels = chain(getLabelFiles(annotationData))
    .groupBy(file => file.label)
    .map((files, label) => ({
      label,
      count: files.length,
      files,
    }))
    .orderBy(file => file.label, [query.sortDir])
    .value();

  const response: DatasetLabelsResponse = {
    count: labels.length,
    labels: labels.slice(query.startFrom, query.startFrom + query.pageSize),
  };

  return response;
}

export function getListData(
  annotationData: DatasetGetAnnotationsResponse | undefined,
  query: DatasetListRequest,
  isLabelView: boolean = false
) {
  const files = isLabelView ? getLabelFiles(annotationData) : getFiles(annotationData);

  const filesFound =
    query.label.length > 0
      ? files.filter(f => f.label?.toLowerCase() === query.label.toLowerCase())
      : files;

  const response: DatasetListResponse = {
    count: filesFound.length,
    files: orderBy(filesFound, file => file[query.sort], [query.sortDir]).slice(
      query.startFrom,
      query.startFrom + query.pageSize
    ),
  };

  return response;
}

type Annotation = DatasetGetAnnotationsResponse['annotations'][number];
export function getAnnotationMap(annotationData: DatasetGetAnnotationsResponse) {
  const annotationMap = new Map<number, Annotation[]>();
  for (const annotation of annotationData.annotations) {
    if (annotationMap.has(annotation.image_id)) {
      annotationMap.set(annotation.image_id, [
        ...(annotationMap.get(annotation.image_id) as Annotation[]),
        annotation,
      ]);
    } else {
      annotationMap.set(annotation.image_id, [annotation]);
    }
  }
  return annotationMap;
}

type Category = DatasetGetAnnotationsResponse['categories'][number];
export function getCategoryMap(annotationData: DatasetGetAnnotationsResponse) {
  const categoryMap = new Map<number, Category>();
  for (const category of annotationData.categories) {
    categoryMap.set(category.id, category);
  }
  return categoryMap;
}

export function packageFile(
  annotations: AnnotationType[],
  image: ImageType,
  category?: string
) {
  return {
    filename: image.file_name,
    id: image.id,
    label: category,
    lastEdited: image.date_captured,
    size: image.size || 0,
    height: image.height || 0,
    width: image.width || 0,
    image,
    annotation: annotations,
  };
}

type File = DatasetListResponse['files'][number] & {
  image?: DatasetGetAnnotationsResponse['images'][number];
  annotation?: AnnotationType[];
};

export function getFiles(annotationData?: DatasetGetAnnotationsResponse) {
  if (!annotationData) {
    return [];
  }

  const annotationMap = getAnnotationMap(annotationData);
  const categoryMap = getCategoryMap(annotationData);

  const files: File[] = [];
  for (const image of annotationData.images) {
    const annotation = annotationMap.get(image.id) || [];

    const catId = annotation[0]?.category_id;
    const category = catId != null ? categoryMap.get(catId) : null;

    const annotations = annotation?.map(item => ({
      ...item,
      label: categoryMap.get(item.category_id as number)?.name as string,
      bbox: item.bbox,
    }));

    files.push(packageFile(annotations, image, category?.name));
  }

  return files;
}

export function getLabelFiles(annotationData?: DatasetGetAnnotationsResponse) {
  if (!annotationData) {
    return [];
  }
  const annotationMap = getAnnotationMap(annotationData);
  const categoryMap = getCategoryMap(annotationData);

  const files: File[] = [];
  for (const image of annotationData.images) {
    const annotation = annotationMap.get(image.id);
    if (!annotation) {
      continue;
    }

    const labels = uniq(annotation.map(item => item.category_id));
    labels.forEach(category_id => {
      const category = category_id != null && categoryMap.get(category_id);
      if (category) {
        const annotations = annotation
          .filter(item => item.category_id === category_id)
          .map(item => ({
            ...item,
            label: categoryMap.get(item.category_id as number)?.name as string,
            bbox: item.bbox,
          }));
        files.push(packageFile(annotations, image, category.name));
      }
    });
  }

  return files;
}

export function scaleAnnotation(
  annotations: DatasetGetAnnotationsResponse['annotations'] | undefined,
  from: {
    width: number;
    height: number;
  },
  to: {
    width: number;
    height: number;
  }
) {
  return annotations?.map(annotation => {
    // Get Scale Factors
    const scaleX = to.width / from.width;
    const scaleY = to.height / from.height;
    const scaledData = {...annotation};
    if (annotation.bbox) {
      const {bbox} = annotation;
      scaledData.bbox = [
        (bbox[0] || 0) * scaleX,
        bbox[1] * scaleY,
        bbox[2] * scaleX,
        bbox[3] * scaleY,
      ];
    } else {
      scaledData.bbox = undefined;
    }
    return scaledData;
  });
}

export function getDistributions(distributionData?: DatasetDistributionResponse) {
  return [
    distributionData?.body.trainDistribution,
    distributionData?.body.validationDistribution,
    distributionData?.body.testDistribution,
    distributionData?.body.unsplitDistribution,
    distributionData?.body.unknownDistribution,
  ].filter((distribution): distribution is DatasetDistribution => Boolean(distribution));
}

export function getLabelCounts(distributionData?: DatasetDistributionResponse) {
  const labelCounts: {[label: string]: number} = {};

  for (const distribution of getDistributions(distributionData)) {
    for (const [label, count] of Object.entries(distribution.annotationCounts)) {
      if (labelCounts[label]) {
        labelCounts[label] += count;
      } else {
        labelCounts[label] = count;
      }
    }
  }

  return Object.entries(labelCounts)
    .sort()
    .map(([label, count]) => ({label, count}));
}

export function getImageCount(distributionData?: DatasetDistributionResponse) {
  const arr = getDistributions(distributionData);
  return arr.length
    ? getDistributions(distributionData)
        .map(distribution => distribution?.imagesCount || 0)
        .reduce((x, y) => x + y)
    : 0;
}

export function getFileCount(distributionData?: DatasetDistributionResponse) {
  let sum = 0;

  const imageCount: number = getImageCount(distributionData);
  if (imageCount > 0) {
    return imageCount;
  }

  for (const {count} of getLabelCounts(distributionData)) {
    sum += count;
  }

  return sum;
}

export function getNumberOfClasses(distributionData?: DatasetDistributionResponse) {
  return Math.max(
    0,
    ...getDistributions(distributionData).map(
      distribution => distribution.labelNames.length
    )
  );
}

export function getPageOptions(pageCount: number) {
  return fromPairs(
    range(1, pageCount + 1).map(pageNumber => [pageNumber, `Page ${pageNumber}`])
  );
}
