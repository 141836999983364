import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import fileSize from 'filesize';
import * as React from 'react';
import {useIntl} from 'react-intl';
import HumanReadableTime from '../../base-components/StudioHumanReadableTime/HumanReadableTime';
import {
  AnnotationType,
  ImageType,
} from '../../types/dataset/DatasetGetAnnotationsResponse';
import {THUMBNAIL_SIZE_LIST} from '../DataPrepare/ClassificationStep/const';
import {DataViewerItem, DataViewerItemProps} from './DataViewerItem';
import './ListViewer.scss';

export type ItemType = {
  filename: string;
  size: number;
  lastEdited: string;
  label?: string;
  id: number | string;
  height?: number;
  width?: number;
  file?: File;
  selected?: boolean;
  image?: ImageType;
  annotation?: AnnotationType[];
  thumbAnnotation?: AnnotationType[];
};

export type ListViewerProps<T> = {
  children: (item: ItemType, pos: number) => React.ReactNode;
  columns: Readonly<
    Array<{
      id: T;
      label: string;
      sortable: boolean;
    }>
  >;
  items?: ItemType[];
  onSort(id: T): void;
  projectId?: string;
  sort: T;
  sortDir: 'asc' | 'desc';
};

type ListViewerRowContextType = {
  index: number;
  item: ItemType;
  projectId?: string;
};

const ListViewerRowContext = React.createContext<ListViewerRowContextType | undefined>(
  undefined
);

export const useListViewerRow = () => {
  const context = React.useContext(ListViewerRowContext);
  return context!;
};

export type ListViewerThumbnailProps = Pick<DataViewerItemProps, 'url'> &
  Partial<DataViewerItemProps>;

export const ListViewerThumbnail = (props: ListViewerThumbnailProps) => {
  return (
    <DataViewerItem
      height={THUMBNAIL_SIZE_LIST}
      width={THUMBNAIL_SIZE_LIST}
      {...props}
      position={props.position || 0}
    />
  );
};

interface ListViewerFilenameProps {
  onClick?: (id: string) => void;
}

export const ListViewerFilename = (props: ListViewerFilenameProps) => {
  const {item} = useListViewerRow();
  return (
    <ListViewerItem className="list-viewer__filename" onClick={props.onClick}>
      {item.filename}
    </ListViewerItem>
  );
};

export const ListViewerFilesize = () => {
  const intl = useIntl();
  const {item} = useListViewerRow();

  return (
    <ListViewerItem>
      {item.size ? fileSize(item.size) : intl.formatMessage({id: 'general.na.short'})}
    </ListViewerItem>
  );
};

export const ListViewerDimensions = () => {
  const {item} = useListViewerRow();
  const height = item.height;
  const width = item.width;
  return (
    <ListViewerItem>
      {height != null && width != null ? width + 'x' + height : ''}
    </ListViewerItem>
  );
};

export const ListViewerLastEdited = () => {
  const intl = useIntl();
  const {item} = useListViewerRow();
  const date = item.lastEdited || item.file?.lastModified;
  return (
    <ListViewerItem>
      {date ? (
        <HumanReadableTime date={date} utc={true} />
      ) : (
        <span>{intl.formatMessage({id: 'general.na.short'})}</span>
      )}
    </ListViewerItem>
  );
};

export const ListViewerLabel = ({label}: {label: string}) => {
  return label ? <ListViewerItem>{label}</ListViewerItem> : null;
};

export const ListViewer = <T extends string>({
  children,
  columns,
  items,
  onSort,
  projectId,
  sort,
  sortDir,
}: ListViewerProps<T>) => (
  <Table className="list-viewer" size="small">
    <TableHead className="list-viewer__head">
      <TableRow>
        {columns.map(column => (
          <TableCell key={column.id}>
            {column.sortable ? (
              <TableSortLabel
                active={sort === column.id}
                direction={sort === column.id ? sortDir : 'asc'}
                onClick={() => onSort(column.id)}
                IconComponent={ArrowDropDown}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              <span>{column.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody className="list-viewer__body">
      {items?.map((item, i) => (
        <ListViewerRowContext.Provider key={i} value={{index: i, item, projectId}}>
          <TableRow>{children(item, i)}</TableRow>
        </ListViewerRowContext.Provider>
      ))}
    </TableBody>
  </Table>
);

export type ListViewerItemProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: any;
};

export const ListViewerItem = ({children, className, onClick}: ListViewerItemProps) => (
  <TableCell className={className} onClick={onClick}>
    {children}
  </TableCell>
);
